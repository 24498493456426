import { useState } from 'react';
import { FieldProps, useFormikContext } from 'formik';
import { Box, Collapse, IconButton, Slider, TableCell, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import MDIconButton from 'material-ui/components/MDIconButton';
import { getCenterOfPolygon } from 'helpers/google-map-helper';
import RadiusIcon from 'assets/icons/radius';
import DeleteIcon from 'assets/icons/delete';
import { IAddressRadiusSearch, IMoversSchema, IPolygonSearch } from 'services/smart-automation/smart-automation.types';
import MDCard from 'material-ui/components/MDCard';
import { useMoversContext } from 'context/movers-context';
import { formatNumber } from 'helpers/formatters';

interface MoversMapRowProps extends FieldProps {
  row: IAddressRadiusSearch | IPolygonSearch;
  index: number;
  type: 'Radius' | 'Polygon';
  map: google.maps.Map;
  setZoom: (zoom: number) => void;
  polygonsHelper: google.maps.Polygon[];
  setPolygonsHelper: (polygonsHelper: google.maps.Polygon[]) => void;
  circlesHelper: google.maps.Circle[];
  setCirclesHelper: (circlesHelper: google.maps.Circle[]) => void;
}

const MoversMapRow = ({
  row,
  index,
  type,
  map,
  setZoom,
  field,
  circlesHelper,
  setCirclesHelper,
  polygonsHelper,
  setPolygonsHelper,
}: MoversMapRowProps) => {
  const { values, setValues, setFieldValue } = useFormikContext<IMoversSchema>();
  const { disabled, targetsCalculated, setMapTouched, mapTouched } = useMoversContext();
  const [open, setOpen] = useState<boolean>(true);
  const customSX = { '&:last-child td, &:last-child th': { border: 0 } };
  const searchShape = type === 'Radius' ? 'addressRadiusSearches' : 'polygonSearches';
  const availableQuantity = values[searchShape][index].availableQuantity;
  const minimumAvailableQuantity = Math.min(1, values[searchShape][index].availableQuantity);
  const errorStatuses = ['Error', 'UnexpectedError', 'CountEngineError'];
  const targetError = values[searchShape][index].availableQuantity < minimumAvailableQuantity || errorStatuses.includes(values[searchShape][index].status);

  const showSlider = values.filter.lookBackMonths && targetsCalculated;

  const handleDeleteArea = (index: number, area: 'addressRadiusSearches' | 'polygonSearches') => {
    const auxSelecteds = { ...values };
    auxSelecteds[area].splice(index, 1);
    setValues(auxSelecteds);
    if (area === 'addressRadiusSearches') {
      const auxCircles = [...circlesHelper];
      auxCircles.splice(index, 1);
      setCirclesHelper(auxCircles);
    } else {
      const auxPolygons = [...polygonsHelper];
      auxPolygons.splice(index, 1);
      setPolygonsHelper(auxPolygons);
    }
    if (!mapTouched) setMapTouched(true);
  };

  const handleSeeOnMapClick = (row: IAddressRadiusSearch | IPolygonSearch) => {
    if ('areaCriteria' in row) {
      map.setCenter({ lat: row.areaCriteria.coordinate.lat, lng: row.areaCriteria.coordinate.lng });
    } else map.setCenter(getCenterOfPolygon(row.polygonCriteria.coordinates));
    setZoom(12);
  };

  return (
    <>
      <TableRow sx={showSlider ? { '& > *': { border: 0 } } : customSX}>
        {showSlider && (
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
        )}
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="center">{type}</TableCell>
        <TableCell align="center">
          {targetsCalculated && !targetError
            ? Math.ceil(
              values.filter.lookBackMonths ? availableQuantity / values.filter.lookBackMonths : availableQuantity / 6
            )
            : '-'}
        </TableCell>
        <TableCell align="center">
          <Tooltip title={'See on map'}>
            <MDIconButton
              backgroundColor={'light'}
              sx={{ p: 0, width: '42px', minWidth: 'unset', mr: 1 }}
              onClick={() => handleSeeOnMapClick(row)}
            >
              <RadiusIcon color={'primary'} />
            </MDIconButton>
          </Tooltip>
          <Tooltip title={'Delete'}>
            <MDIconButton
              disabled={disabled}
              backgroundColor={'light'}
              sx={{ p: 0, width: '42px', minWidth: 'unset' }}
              onClick={() => handleDeleteArea(index, type === 'Radius' ? 'addressRadiusSearches' : 'polygonSearches')}
            >
              <DeleteIcon color={'error'} />
            </MDIconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      {showSlider && (
        <TableRow sx={customSX}>
          <TableCell />
          <TableCell sx={{ pt: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <MDCard color={'light'} sx={{ px: 2, py: 1, mr: 2 }}>
                {targetError ? (
                  <Typography variant="h5" color="darkred" textAlign={'center'}>
                    Not return enough results - please widen the search
                  </Typography>
                ) : (
                  <>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                      <Typography variant="h5">Lookback Period: One time send</Typography>
                      <TextField
                        {...field}
                        focused
                        size="small"
                        type="number"
                        color="primary"
                        variant="outlined"
                        disabled={disabled}
                        inputProps={{ min: minimumAvailableQuantity, max: availableQuantity }}
                      />
                    </Box>
                    <Slider
                      size="small"
                      min={minimumAvailableQuantity}
                      max={availableQuantity}
                      disabled={disabled}
                      value={field.value}
                      onChange={(_, value) => setFieldValue(field.name, Array.isArray(value) ? value[0] : value)}
                      valueLabelDisplay="auto"
                      valueLabelFormat={(value) => formatNumber(value)}
                      marks={[
                        { value: minimumAvailableQuantity, label: formatNumber(minimumAvailableQuantity) },
                        { value: availableQuantity, label: formatNumber(availableQuantity) }
                      ]}
                    />
                  </>
                )}
              </MDCard>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default MoversMapRow;
