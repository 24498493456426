import { Icon } from "@mui/material"
// import ImgLy from "pages/imgly"
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import { LocalOffer } from "@mui/icons-material";

import OrderPage from "pages/order/order"
import OrdersPage from "pages/orders"
import ProfilePage from "pages/settings/profile"
import ReferalPage from "pages/settings/refer-a-friend"
import SubscriptionsPage from "pages/subscriptions"
import HomePage from "pages/home"
import AddressBookPage from "pages/address-book";
import IntegrationSetupPage from "pages/integrations/setup"
import SmartAutomationPage from "pages/smart-automation"
import SmartAutomationUpsertPage from "pages/smart-automation-upsert"
import CallTrackingOrderPage from "./pages/call-tracking/order";
import CallTrackingHistoryPage from "pages/call-tracking/manage-numbers";
import RadiusMailingPage from "pages/recipient-search/search";
import MyTemplatesPage from "pages/my-templates";
import Billing from "pages/settings/billing";
import TemplatePage from "pages/order/template";

// Admin
import AdminCustomerSuccessDashboardPage from "pages/admin/customer-success"
import AdminSalesRevenueDashboard from "pages/admin/sales-revenue"
import AdminFeedbackPage from "./pages/admin/feedback";

// SuperAdmin
import AdminAssignBDRRepPage from "./pages/superadmin/assign-bdr";
import AdminPricingTiersPage from "./pages/superadmin/pricing-tiers";
import AdminSubscriptionPricing from "./pages/superadmin/subscription-pricing";
import AdminProfilesPage from "./pages/superadmin/profiles";
import AdminOrdersPage from "./pages/superadmin/order";
import AdminDashboardPage from "./pages/superadmin/dashboard";
import AdminIntegrationOrdersPage from "./pages/superadmin/integration-orders";
import AdminChangeOrderStatusPage from "./pages/superadmin/change-order-status";
import AdminHailTracePage from "pages/superadmin/hail-trace"
import MatchbackPage from "pages/superadmin/matchback";
import SuperAdminStormAutomationHistoryPage from "./pages/superadmin/storm-automation/history";
import SuperAdminStormAutomationManagePage from "./pages/superadmin/storm-automation/manage";
import AdminOrdersWithIssues from "pages/superadmin/orders-issues"

import SidebarHomeIcon from "./assets/icons/sidebar/sidebar-home";
import SidebarOrdersIcon from "./assets/icons/sidebar/sidebar-orders";
import SidebarTemplatesIcon from "./assets/icons/sidebar/sidebar-templates";
import SidebarAddressBookIcon from "./assets/icons/sidebar/sidebar-address-book";
import SidebarPhoneIcon from "./assets/icons/sidebar/sidebar-phone";
import SidebarRadiusMailIcon from "./assets/icons/sidebar/sidebar-radius-mail";
import SidebarIntegrationsIcon from "./assets/icons/sidebar/sidebar-integraions";
import SidebarSubscriptionsIcon from "./assets/icons/sidebar/sidebar-subscriptions";
import SidebarSettingsIcon from "./assets/icons/sidebar/sidebar-settings";
import SidebarHelpIcon from "./assets/icons/sidebar/sidebar-help";
// import AdminAssignSalesPage from "./pages/superadmin/assign-sales";

import MaintenancePage from "pages/settings/maintenance"
import StormAutomation from "./pages/storm-automation/new";
import HailTraceLogoSmall from "./assets/images/hailtrace-logo-small.png";
import StormAutomationHistoryPage from "./pages/storm-automation/history";
import IntegrationOrdersPage from "./pages/integrations/integration-orders";

import RecipientSearchPage from "pages/recipient-search/search";
import RecipientSearchHistoryPage from "pages/recipient-search/history";
import RadiusMailPage from "./pages/radius-mail/search";
import RadiusMailHistoryPage from "./pages/radius-mail/history";
import OrderSuccessPage from "pages/order/order-success";
import SmartAutomationSetup from "pages/smart-automation-setup";
import DiscountCodes from "pages/discount-codes";
import DoNotMail from "pages/do-not-mail";
import SubscriptionCredits from "pages/superadmin/subscription-credits";
import DataSourceSetup from "pages/data-source-setup";
import SuperAdminDiscounts from "pages/superadmin/discounts";
import SuperAdminSubscriptionTransfer from "pages/superadmin/subscription-transfer";
import DiscountApproval from "pages/superadmin/discount-approval";
import AdminOrderTracking from "pages/admin/order-tracking";
import EnrichmentReport from "pages/enrichment-report";

const mainRoutes: any[] = [
  {
    icon: <SidebarHomeIcon/>,
    type: "collapse",
    name: "Home",
    key: "home",
    route: "/home",
    component: <HomePage />,
    noCollapse: true,
  },
  {
    icon: <SidebarOrdersIcon/>,
    type: "collapse",
    name: "Orders",
    key: "orders",
    route: "/orders",
    component: <OrdersPage />,
    noCollapse: true,
  },
  {
    icon: <SidebarTemplatesIcon/>,
    type: "collapse",
    name: "My Templates",
    key: "my-templates",
    route: "/my-templates",
    component: <MyTemplatesPage />,
    noCollapse: true,
  },
  {
    icon: <SidebarPhoneIcon/>,
    type: "collapse",
    name: "Call tracking",
    key: "call-tracking",
    route: "/call-tracking",
    collapse: [
      {
        name: "Order",
        key: "order",
        route: "/call-tracking/order",
        component: <CallTrackingOrderPage/>
      },
      {
        name: "Manage Numbers",
        key: "history",
        route: "/call-tracking/history",
        component: <CallTrackingHistoryPage/>
      }
    ]
  },
  { type: "divider"},
  {
    type: "collapse",
    name: "Discount Codes",
    key: "discount-codes",
    route: "/discount-codes",
    icon: <LocalOffer />,
    component: <DiscountCodes />,
    noCollapse: true
    // hide: process.env.REACT_APP_ENVIRONMENT !== "dev"
  },
  {
    icon: <SidebarAddressBookIcon/>,
    type: "collapse",
    name: "Address Book",
    key: "address-book",
    route: "/address-book",
    component: <AddressBookPage />,
    noCollapse: true,
  },
  {
    icon: <TravelExploreIcon/>,
    type: "collapse",
    name: "Recipient Search",
    key: "recipient-search",
    route: "/recipient-search",
    component: <RecipientSearchHistoryPage/>,
    noCollapse: true,
  },
  {
    icon: <SidebarRadiusMailIcon/>,
    // hide: true,
    type: "collapse",
    name: "Radius Mail",
    key: "radius-mailing",
    route: "/radius-mailing",
    component: <RadiusMailHistoryPage/>,
    noCollapse: true,
  },
  { type: "divider"},
  {
    icon: <img src={HailTraceLogoSmall} alt={""} style={{width: "24px", height: "auto"}}/>,
    type: "collapse",
    name: "Storm Automation",
    key: "storm-automation",
    route: "/storm-automation",
    component: <StormAutomationHistoryPage/>,
    noCollapse: true,
    hide: true,
  },
  {
    type: "collapse",
    name: "Smart Automation",
    key: "smart-automation",
    route: "/smart-automation",
    icon: <AutoModeIcon />,
    component: <SmartAutomationPage/>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Integration Orders",
    key: "integration-orders",
    route: "/integration-orders",
    icon: <SidebarIntegrationsIcon/>,
    component: <IntegrationOrdersPage />,
    noCollapse: true
  },
  { type: "divider"},
  {
    icon: <SidebarSubscriptionsIcon/>,
    type: "collapse",
    name: "Subscriptions",
    key: "subscriptions",
    route: "/subscriptions",
    noCollapse: true,
    component: <SubscriptionsPage/>
  },
  {
    icon: <SidebarSettingsIcon/>,
    type: "collapse",
    name: "Settings",
    key: "settings",
    collapse: [
      {
        icon: <Icon fontSize="medium">account_circle</Icon>,
        type: "collapse",
        name: "Profile",
        key: "profile",
        route: "/settings/profile",
        component: <ProfilePage />
      },
      {
        icon: <Icon fontSize="medium">account_circle</Icon>,
        type: "collapse",
        name: "Billing",
        key: "billing",
        route: "/settings/billing",
        component: <Billing/>,
      },
      {
        icon: <Icon fontSize="medium">account_circle</Icon>,
        type: "collapse",
        name: "Refer a Friend",
        key: "refer",
        route: "/settings/refer",
        component: <ReferalPage/>
      },
      {
        icon: <Icon fontSize="medium">account_circle</Icon>,
        type: "collapse",
        name: "Smart Automation Setup",
        key: "smart-automation-setup",
        route: "/settings/smart-automation-setup",
        component: <SmartAutomationSetup/>
      },
      {
        type: "collapse",
        name: "Do Not Mail",
        key: "do-not-mail",
        route: "/do-not-mail",
        component: <DoNotMail />,
      },
    ]
  },
  {
    icon: <SidebarHelpIcon/>,
    type: "collapse",
    name: "Help",
    key: "help",
    collapse: [
      {
        type: "collapse",
        name: "Tutorials",
        key: "tutorials",
        route: "/help/tutorials",
        href: "https://www.lettrlabs.com/help-guides"
      },
      {
        type: "collapse",
        name: "Suggestions",
        key: "help/suggestions",
        href: "https://lettrlabs.kampsite.co/"
      }
    ]
  },
  //NO Side Nav Components
  {
    type: "plainRouteNoSideNav",
    name: "EnrichmentReport",
    key: "EnrichmentReport",
    route: "/enrichment-report/:id",
    component: <EnrichmentReport />,
    noCollapse: true,
  },
  {
    type: "plainRouteNoSideNav",
    name: "OrderSuccess",
    key: "orderWithIdSuccess",
    route: "/order/:id/success",
    component: <OrderSuccessPage />,
    noCollapse: true,
  },
  {
    type: "plainRouteNoSideNav",
    name: "Maintenance",
    key: "maintenance",
    route: "/maintenance",
    component: <MaintenancePage />,
    noCollapse: true,
  },
  {
    type: "plainRouteNoSideNav",
    name: "Order",
    key: "orderWithId",
    route: "/order/:id",
    component: <OrderPage />,
    noCollapse: true,
  },
  {
    type: "plainRouteNoSideNav",
    name: "Order",
    key: "templateWithId",
    route: "/template/:id",
    component: <TemplatePage />,
    noCollapse: true,
  },
  /*{
    type: "plainRouteNoSideNav",
    name: "imgly",
    key: "imgly",
    route: "/imgly",
    component: <ImgLy />,
    noCollapse: true,
  },*/
  {
    type: "plainRouteNoSideNav",
    name: "Order",
    key: "order",
    route: "/recipient-search/order/:id",
    component: <RadiusMailingPage />,
  },
  {
    type: "plainRouteNoSideNav",
    name: "Search",
    key: "order",
    route: "/radius-mailing/order/:id",
    component: <RadiusMailPage />,
  },
  {
    type: "plainRouteNoSideNav",
    name: "Order",
    key: "storm-automation",
    route: "/storm-automation/order/:id",
    component: <StormAutomation />,
  },
  {
    icon: <Icon fontSize="medium">add_circle</Icon>,
    type: "plainRouteNoSideNav",
    name: "New Template",
    key: "template",
    route: "/template",
    component: <TemplatePage />,
    noCollapse: true,
  },
  {
    icon: <Icon fontSize="medium">add_circle</Icon>,
    type: "plainRouteNoSideNav",
    name: "New Order",
    key: "order",
    route: "/order",
    component: <OrderPage />,
    noCollapse: true,
  },
  {
    type: "plainRouteNoSideNav",
    name: "Manage Storm Automation",
    key: "manage-storm-automation",
    route: "/admin/manage-storm-automation/:id",
    component: <SuperAdminStormAutomationManagePage />,
    noCollapse: true,
  },
  {
    type: "plainRouteNoSideNav",
    name: "Smart Automation",
    key: "smart-automation",
    route: "/smart-automation/:id",
    component: <SmartAutomationUpsertPage />,
    noCollapse: true,
  },
  {
  type: "plainRouteNoSideNav",
    name: "Recipient Search",
    key: "recipient-search",
    route: "/recipient-search/order",
    component: <RecipientSearchPage/>,
    noCollapse: true,
  },
  {
    type: "plainRouteNoSideNav",
    name: "Radius Mailing",
    key: "radius-mailing",
    route: "/radius-mailing/order",
    component: <RadiusMailPage/>,
    noCollapse: true,
  },
  {
    type: "plainRouteNoSideNav",
    name: "Storm Automation",
    key: "storm-automation",
    route: "/storm-automation/order",
    component: <StormAutomation/>,
    noCollapse: true,
  },
  {
    type: "plainRouteNoSideNav",
    name: "Integration Orders",
    key: "integration-orders",
    route: "/integration-orders/setup",
    component: <IntegrationSetupPage/>,
    noCollapse: true,
  },
  {
    type: "plainRouteNoSideNav",
    name: "Setup Source",
    key: "oauth-setup",
    route: "/oauth/setup/:source",
    component: <DataSourceSetup/>,
    noCollapse: true,
  },
]

const adminRoutes: any[] = [
{
    type: "collapse",
    name: "Admin",
    key: "collapse",
    icon: <Icon fontSize="medium">admin_panel_settings</Icon>,
    collapse: [
      {
        name: "Customer Feedback",
        key: "feedback",
        route: "/admin/feedback",
        icon: <Icon fontSize="medium"></Icon>,
        component: <AdminFeedbackPage/>,
      },
      {
        name: "Customer Success",
        key: "customer-success",
        route: "/admin/customer-success",
        icon: <Icon fontSize="medium"></Icon>,
        component: <AdminCustomerSuccessDashboardPage/>,
      },
      {
        name: "Sales Dashboard",
        key: "sales-revenue",
        route: "/admin/sales-revenue",
        icon: <Icon fontSize="medium"></Icon>,
        component: <AdminSalesRevenueDashboard/>,
      },
      {
        name: "Change Order Status",
        key: "order-statuses",
        route: "/admin/order-statuses",
        icon: <Icon fontSize="medium"></Icon>,
        component: <AdminChangeOrderStatusPage/>,
      },
      {
        name: "Order",
        key: "admin-orders",
        route: "/admin/order",
        icon: <Icon fontSize="medium"></Icon>,
        component: <AdminOrdersPage/>,
      },
      {
        name: "Order Tracking",
        key: "order-tracking",
        route: "/admin/order-tracking",
        icon: <Icon fontSize="medium"></Icon>,
        component: <AdminOrderTracking />
      },
    ]
  }
]

const superAdminRoutes = {
    type: "collapse",
    name: "Super Admin",
    key: "superadmin-collapse",
    icon: <Icon fontSize="medium">admin_panel_settings</Icon>,
    collapse: [
      {
        name: "Discount Approval",
        key: "discount-approval",
        route: "/superadmin/discount-approval",
        icon: <Icon fontSize="medium"></Icon>,
        component: <DiscountApproval/>,
        hide: true
      },
      {
        name: "Assign Sales and BDR",
        key: "assign-bdr",
        route: "/superadmin/assign-bdr",
        icon: <Icon fontSize="medium"></Icon>,
        component: <AdminAssignBDRRepPage/>,
      },
      {
        name: "Pricing Tiers",
        key: "pricing-tiers",
        route: "/superadmin/pricing-tiers",
        icon: <Icon fontSize="medium"></Icon>,
        component: <AdminPricingTiersPage/>,
      },
      {
        name: "Subscription Pricing",
        key: "subscription-pricing",
        route: "/superadmin/subscription-pricing",
        icon: <Icon fontSize="medium"></Icon>,
        component: <AdminSubscriptionPricing/>,
      },
      {
        name: "Hail Trace Service Areas",
        key: "hailtrace",
        route: "/superadmin/hailtrace",
        icon: <Icon fontSize="medium"></Icon>,
        component: <AdminHailTracePage/>,
      },
      {
        name: "Storm Automation",
        key: "radius-mail",
        route: "/superadmin/radius-mail",
        icon: <Icon fontSize="medium"></Icon>,
        component: <SuperAdminStormAutomationHistoryPage/>,
      },
      {
        name: "Profiles",
        key: "profiles",
        route: "/superadmin/profiles",
        icon: <Icon fontSize="medium"></Icon>,
        component: <AdminProfilesPage/>,
      },
      {
        name: "Dashboard",
        key: "dashboard",
        route: "/superadmin/dashboard",
        icon: <Icon fontSize="medium"></Icon>,
        component: <AdminDashboardPage/>,
      },
      {
        name: "Integration Orders",
        key: "integration-orders",
        route: "/superadmin/integration-orders",
        icon: <Icon fontSize="medium"></Icon>,
        component: <AdminIntegrationOrdersPage/>,
      },
      {
        name: "Orders with Issues",
        key: "orders-issues",
        route: "/superadmin/orders-issues",
        icon: <Icon fontSize="medium"></Icon>,
        component: <AdminOrdersWithIssues/>,
      },
      {
        name: "Add/Remove Credits",
        key: "subscription-credits",
        route: "/superadmin/subscription-credits",
        icon: <Icon fontSize="medium"></Icon>,
        component: <SubscriptionCredits/>,
      },
      {
        name: "Add / Remove Discounts",
        key: "manage-discounts",
        route: "/superadmin/manage-discounts",
        icon: <Icon fontSize="medium"></Icon>,
        component: <SuperAdminDiscounts/>,
      },
      {
        name: "Transfer Subscription",
        key: "subscription-transfer",
        route: "/superadmin/subscription-transfer",
        icon: <Icon fontSize="medium"></Icon>,
        component: <SuperAdminSubscriptionTransfer/>,
      },
      {
        name: "Matchback",
        key: "matchback",
        route: "/superadmin/matchback",
        icon: <Icon fontSize="medium"></Icon>,
        component: <MatchbackPage/>,
      },
    ]
  }

export {mainRoutes, adminRoutes, superAdminRoutes}
