import { Field, FieldInputProps, FieldProps, Form, FormikValues, useFormikContext } from 'formik';
import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Slider,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { useQuery } from '@tanstack/react-query';
import MDCard from 'material-ui/components/MDCard';
import SelectTemplate from 'components/select-template';
import FormikErrorMessage from 'components/formik-error-message';
import { formatNumber } from 'helpers/formatters';
import RadioSelectWrapper from 'components/radio-select-wrapper';
import MoversMap from './movers-map';
import { orderProducUsesLetterUSPSPricing } from 'models/enums/ProductTypes';
import MDButton from 'material-ui/components/MDButton';
import { IMoversSchema, PostageTypeEnum } from 'services/smart-automation/smart-automation.types';
import { useGlobal } from 'context/global-context';
import useSmartAutomationService from 'services/smart-automation';
import { useMoversContext } from 'context/movers-context';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loader from 'components/loader';

interface MoversFormProps {
  updatedValues?: IMoversSchema;
  startPolling?: boolean;
  setStartPolling: (startPolling?: boolean) => void;
}

const AutoCreate = (): null => {
  const { values, submitForm } = useFormikContext<IMoversSchema>();
  const { id: automationId } = useParams();
  const isNew = automationId === 'new';

  useEffect(() => {
    if (isNew && values.template) submitForm();
  }, [values, submitForm, isNew]);

  return null;
};

const MoversForm = ({ startPolling, updatedValues, setStartPolling }: MoversFormProps) => {
  const { setShowLoader } = useGlobal();
  const { id: automationId } = useParams();
  const isNew = automationId === 'new';
  const { getMoversPoll } = useSmartAutomationService();
  const { values, setFieldValue, setValues } = useFormikContext<IMoversSchema>();
  const { disabled, targetsCalculated, setTargetsCalculated, setMapTouched, pollingZips } = useMoversContext();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const { refetch: poll } = useQuery({
    queryFn: () => getMoversPoll(updatedValues?.recipientSearchId || values.recipientSearchId),
    queryKey: ['getMoversPoll'],
    enabled: false,
  });

  useEffect(() => {
    if (!isNew) setIsSaving(false);
  }, [isNew]);

  useEffect(() => {
    if (updatedValues) setValues(updatedValues);
  }, [setValues, updatedValues]);

  useEffect(() => {
    const polling = async () => {
      const interval = setInterval(async () => {
        const { data } = await poll();
        const allValidPolygonCounts = data?.payload.polygonSearches.length <= 0 || data?.payload.polygonSearches.every((el) => el.status === 'Complete');
        const allValidRadiusCount = data?.payload.addressRadiusSearches.length <= 0 || data?.payload.addressRadiusSearches.every((el) => el.status === 'Complete');
        if (allValidPolygonCounts) {
          data?.payload.polygonSearches.forEach((polygon, index) => {
            setFieldValue(`polygonSearches.${index}.availableQuantity`, polygon.availableQuantity);
            setFieldValue(`polygonSearches.${index}.desiredQuantity`, polygon.desiredQuantity);
          });
        }
        if (allValidRadiusCount) {
          data?.payload.addressRadiusSearches.forEach((radius, index) => {
            setFieldValue(`addressRadiusSearches.${index}.availableQuantity`, radius.availableQuantity);
            setFieldValue(`addressRadiusSearches.${index}.desiredQuantity`, radius.desiredQuantity);
          });
        }
        if (allValidPolygonCounts && allValidRadiusCount) {
          setTargetsCalculated(true);
          setShowLoader(false);
          clearInterval(interval);
          setStartPolling(false);
        }
      }, 20000);
    };
    if (startPolling) polling();
  }, [poll, setFieldValue, setShowLoader, setStartPolling, setTargetsCalculated, startPolling]);

  const handleFilterChange = (field: FieldInputProps<FormikValues>, e: SelectChangeEvent) => {
    if (targetsCalculated) setTargetsCalculated(false);
    field.onChange(e);
  };

  return (
    <Form>
      <Dialog open={isSaving}>
        <DialogContent>
          <Loader />
        </DialogContent>
      </Dialog>
      <Grid container spacing={2} py={1}>
        <Grid item md={12} lg={5}>
          <MDCard sx={{ p: 2, display: 'flex', gap: 3 }}>
            <AutoCreate />
            <Box>
              <Typography variant="h4" pb={1}>
                Template
              </Typography>
              <Field name="template">
                {({ field, meta }: FieldProps) => (
                  <SelectTemplate
                    order={values}
                    onDelete={() => setFieldValue('template', null)}
                    onTemplateChange={(template) => {
                      if (isNew) setIsSaving(true);
                      setFieldValue('template', template);
                    }}
                    template={field.value}
                    meta={meta}
                    disabled={disabled}
                  />
                )}
              </Field>
            </Box>
            <Box>
              <Typography variant="h4" pb={1}>
                Filters
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={3.5}>
                  <Typography fontSize={14} fontWeight={'bold'}>
                    Mover Type
                  </Typography>
                  <Field name="filter.ownerOrRenterFilter">
                    {({ field, meta }: FieldProps) => (
                      <>
                        <Select
                          {...field}
                          fullWidth
                          displayEmpty
                          disabled={disabled}
                          error={meta.touched && !!meta.error}
                          onChange={(e) => handleFilterChange(field, e)}
                        >
                          <MenuItem value={null} disabled>
                            Select an option
                          </MenuItem>
                          <MenuItem value={0}>Owner</MenuItem>
                          <MenuItem value={1}>Renter</MenuItem>
                        </Select>
                        <FormikErrorMessage meta={meta} />
                      </>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={5}>
                  <Typography fontSize={14} fontWeight={'bold'}>
                    Property Type
                  </Typography>
                  <Field name="filter.propertyTypeFilter">
                    {({ field, meta }: FieldProps) => (
                      <>
                        <Select
                          {...field}
                          fullWidth
                          displayEmpty
                          disabled={disabled}
                          onChange={(e) => handleFilterChange(field, e)}
                        >
                          <MenuItem value={2}>All</MenuItem>
                          <MenuItem value={0}>Single Family Home</MenuItem>
                          <MenuItem value={1}>Multi Family Dwelling Unit</MenuItem>
                        </Select>
                        <FormikErrorMessage meta={meta} />
                      </>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={3.5}>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography fontSize={14} fontWeight={'bold'}>
                      Lookback Period
                    </Typography>
                    <Tooltip
                      componentsProps={{ tooltip: { sx: { width: '280px', maxWidth: 'unset' } } }}
                      title={`The lookback period defines how far back in time you want to fetch ${values.filter.ownerOrRenterFilter === 1 ? 'Renters' : 'Movers'
                        } when enabling it for the first time.`}
                    >
                      <Typography fontSize={0}>
                        <InfoOutlined fontSize={'inherit'} color="disabled" />
                      </Typography>
                    </Tooltip>
                  </Box>
                  <Field name="filter.lookBackMonths">
                    {({ field, meta }: FieldProps) => (
                      <>
                        <Select
                          {...field}
                          fullWidth
                          displayEmpty
                          disabled={disabled}
                          value={field.value || null}
                          error={meta.touched && !!meta.error}
                          onChange={(e) => handleFilterChange(field, e)}
                        >
                          <MenuItem value={null}>Start today</MenuItem>
                          {Array.from({ length: 6 }, (_, i) => ++i).map((el) => (
                            <MenuItem value={el} key={el}>
                              {el} Month{el > 1 && 's'}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormikErrorMessage meta={meta} />
                      </>
                    )}
                  </Field>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Typography variant="h4" pb={1}>
                Sending Options
              </Typography>
              <Box display={'flex'} justifyContent={'space-between'} mt={1}>
                <Typography fontSize={14} fontWeight={'bold'}>
                  Max number of cards sent per month
                </Typography>
                <Typography fontSize={14} fontWeight={'normal'}>
                  Up to 10,000
                </Typography>
              </Box>
              <Field name="sendingOptions.maxNumberCardsSentPerMonth">
                {({ field }: FieldProps) => (
                  <Box ml={2.5} mr={3}>
                    <Slider
                      min={100}
                      max={10000}
                      step={100}
                      disabled={disabled}
                      value={field.value}
                      valueLabelDisplay="auto"
                      valueLabelFormat={(value) => formatNumber(value)}
                      onChange={(_, value: number | number[]) =>
                        setFieldValue('sendingOptions.maxNumberCardsSentPerMonth', value)
                      }
                      marks={[
                        { value: 100, label: '100' },
                        { value: 2000, label: '2,000' },
                        { value: 4000, label: '4,000' },
                        { value: 6000, label: '6,000' },
                        { value: 8000, label: '8,000' },
                        { value: 10000, label: '10,000' },
                      ]}
                    />
                  </Box>
                )}
              </Field>
            </Box>
            <Box>
              <Typography variant={'h4'} pb={1}>
                Delivery Option
              </Typography>
              <Field name="sendingOptions.postageType">
                {({ field }: FieldProps) => (
                  <FormControl fullWidth disabled={disabled}>
                    <RadioGroup
                      value={field.value}
                      onChange={(e) => setFieldValue('sendingOptions.postageType', Number(e.target.value))}
                    >
                      <RadioSelectWrapper selected={field.value === PostageTypeEnum.FirstClass}>
                        <FormControlLabel
                          control={<Radio />}
                          value={PostageTypeEnum.FirstClass}
                          label={
                            <Box display={'flex'} alignItems={'center'} gap={1}>
                              <Typography variant="h5">First Class Postage</Typography>
                              <Typography variant="h5" fontWeight={'normal'}>
                                (3-5 day USPS estimated delivery)
                              </Typography>
                              <Chip
                                label={
                                  values.template?.postageTypePrices?.FirstClass
                                    ? `+$${values?.template?.postageTypePrices?.FirstClass?.toFixed(2)}`
                                    : orderProducUsesLetterUSPSPricing(values?.template?.product)
                                      ? "+$0.15"
                                      : "+$0.05"
                                }
                              />
                            </Box>
                          }
                        />
                      </RadioSelectWrapper>
                      <Box mt={1} />
                      <RadioSelectWrapper selected={field.value === PostageTypeEnum.Standard}>
                        <FormControlLabel
                          control={<Radio />}
                          value={PostageTypeEnum.Standard}
                          label={
                            <Box display={'flex'} alignItems={'center'} gap={1}>
                              <Typography variant="h5">Standard Postage</Typography>
                              <Typography variant="h5" fontWeight={'normal'}>
                                (10-14 day USPS estimated delivery)
                              </Typography>
                            </Box>
                          }
                        />
                      </RadioSelectWrapper>
                    </RadioGroup>
                  </FormControl>
                )}
              </Field>
            </Box>
          </MDCard>
        </Grid>
        <Grid item md={12} lg={7}>
          <MDCard sx={{ p: 2 }}>
            <Stack spacing={2}>
              <MoversMap />
              <MDButton
                color="light"
                type={targetsCalculated ? 'button' : 'submit'}
                disabled={disabled || targetsCalculated || pollingZips}
                onClick={() => setMapTouched(true)}
              >
                {pollingZips ? 'Loading Zip Codes...' : 'Calculate Targets'}
              </MDButton>
              <MDButton
                type={targetsCalculated ? 'submit' : 'button'}
                disabled={disabled || !targetsCalculated}
                onClick={() => setMapTouched(true)}
              >
                Enable Automation {values.filter.lookBackMonths && '& Checkout'}
              </MDButton>
            </Stack>
          </MDCard>
        </Grid>
      </Grid>
    </Form>
  );
};

export default MoversForm;
