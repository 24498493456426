import MDBox from "material-ui/components/MDBox";
import MDCard from "material-ui/components/MDCard";
import OrderStepper from "../components/stepper";
import React, {useEffect, useState} from "react";
import ProductSubpage from "../components/subpages/1-product-subpage";
import DesignSubpage from "../components/subpages/2-design-subpage";
import ReturnAddressSubpage from "../components/subpages/3-return-address-subpage";
import ReviewSubpage from "../components/subpages/5-review-subpage";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {useParams} from "react-router-dom";
import OrderService from "services/order";
import {TemplateSteps} from "models/orderSteps";
import {EditorProvider, useEditor} from "context/editor-context";
import {PageSettingsProvider} from "context/page-settings-context";
import {Grid} from "@mui/material";
import {OrderArtifactType} from "models/enums/orderArtifactType";
import {Order} from "models/order";
import {IProduct} from "models/product";
import {OrderParameter} from "models/orderParameter";
import TemplateService from "services/template";
import {ProductTypes} from "models/enums/ProductTypes";
import { useGlobal } from "context/global-context";
import { OrderFontDefaults } from "models/enums/OrderDefaults";

interface ContentProps {
    template: Order
    setTemplate: Function
}

function Content({template, setTemplate}: ContentProps) {
    const queryClient = useQueryClient()

    const {setShowLoader} = useGlobal()
    const {postOrderParameters} = OrderService()
    const {postTemplate} = TemplateService()

    const {saveCurrentScene, resetEditor, setKeepOutWhite} = useEditor()

    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)

    const [activeStep, setActiveStep] = useState<TemplateSteps>(null)
    const [maxStep, setMaxStep] = useState<TemplateSteps>(null)

    const steps = [
        {
            key: 0,
            label: "Product"
        },
        {
            key: 1,
            label: "Design"
        },
        {
            key: 2,
            label: "Return Address"
        },
        {
            key: 3,
            label: "Review"
        },
    ]

    //--- Product Page Variables
    const [showWarningModal, setShowWarningModal] = useState<boolean>(false)
    const [product, setProduct] = useState<IProduct>(null)
    const [orderName, setOrderName] = useState<string>("")

    //--- Design Page Variables
    const [hasSceneWarnings, setHasSceneWarnings] = useState<boolean>(false)
    const [orderParameters, setOrderParameters] = useState<OrderParameter[]>([])
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false)

    const postOrderMutation = useMutation({
        mutationFn: () => {
            return postTemplate(template)
        },
        onMutate: () => {
            setShowLoader(true)
        },
        onSuccess: () => {
            setShowLoader(false)
        }
    })

    // Decides which step the user should start on and which should be the max step
    useEffect(() => {
        if (!template) {
            return
        }

        if (isFirstLoad) {
            setActiveStep(TemplateSteps.ProductSubpage)
            setIsFirstLoad(false)
        }

        if (!template.product || !template.orderArtifacts?.length) {
            setMaxStep(TemplateSteps.ProductSubpage)

            return
        }

        if (!template.orderArtifacts.find(a => a.artifactType === OrderArtifactType.CreativeEditorScene)) {
            setMaxStep(TemplateSteps.DesignSubpage)

            return
        }

        if (!template.orderArtifacts.find(a => a.artifactType === OrderArtifactType.EnvelopeScene)) {
            setMaxStep(TemplateSteps.ReturnAddressSubpage)

            return
        }

        setMaxStep(TemplateSteps.ReviewSubpage)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template]);

    useEffect(() => {
        if (template && activeStep !== null) {
            setShowLoader(false)
        } else {
            setShowLoader(true)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template, activeStep]);

    async function onNext(newStep?: number) {
        if (activeStep === TemplateSteps.ProductSubpage) {
            let sceneArtifact = template.orderArtifacts.find(
                (a) => a.artifactType === OrderArtifactType.CreativeEditorScene
            );

            if (sceneArtifact && template.product !== product.name) {
                setShowWarningModal(true)
            } else {
                template.product = product.name
                if ((template?.fontSizeReturnAddress ?? 0) === 0)
                    template.fontSizeReturnAddress = product.name === ProductTypes.HandwrittenPostCardA8 ? OrderFontDefaults.DefaultPostcardFontSizeReturnAddress : OrderFontDefaults.DefaultHandwrittenFontSizeReturnAddress;

                await postOrderMutation.mutateAsync()
                setShowWarningModal(false)

                setActiveStep(newStep != null ? newStep : TemplateSteps.DesignSubpage)
            }
        } else if (activeStep === TemplateSteps.DesignSubpage) {
            if (hasSceneWarnings) {
                setShowErrorModal(true)

                return
            }
            
            await setKeepOutWhite()

            setShowLoader(true)

            await saveCurrentScene("Card")

            resetEditor()

            await postOrderParameters(template.id, orderParameters)

            postOrderMutation.mutateAsync().then(() => {
                queryClient.invalidateQueries({queryKey: ["template", template.id]})
                queryClient.invalidateQueries({queryKey: ["orderParameters", template.id]})

                setActiveStep(newStep != null ? newStep : TemplateSteps.ReturnAddressSubpage)
            })
        } else if (activeStep === TemplateSteps.ReturnAddressSubpage) {
            setShowLoader(true)

            await saveCurrentScene("Envelope")

            postOrderMutation.mutateAsync().then(() => {
                resetEditor()

                queryClient.invalidateQueries({queryKey: ["template", template.id]})

                setActiveStep(newStep != null ? newStep : TemplateSteps.ReviewSubpage)
            })
        }
    }

    async function onBack(newStep?: number) {
        if (activeStep === TemplateSteps.DesignSubpage) {
            setShowLoader(true)

            await saveCurrentScene("Card")

            resetEditor()

            await postOrderParameters(template.id, orderParameters)

            postOrderMutation.mutateAsync().then(() => {
                queryClient.invalidateQueries({queryKey: ["template", template.id]})
                queryClient.invalidateQueries({queryKey: ["orderParameters", template.id]})

                setActiveStep(newStep != null ? newStep : TemplateSteps.ProductSubpage)
            })
        } else if (activeStep === TemplateSteps.ReturnAddressSubpage) {
            setShowLoader(true)

            await saveCurrentScene("Envelope")

            postOrderMutation.mutateAsync().then(() => {
                queryClient.invalidateQueries({queryKey: ["template", template.id]})

                resetEditor()

                setActiveStep(newStep != null ? newStep : TemplateSteps.DesignSubpage)
            })
        } else if (activeStep === TemplateSteps.ReviewSubpage) {
            setActiveStep(newStep != null ? newStep : TemplateSteps.ReturnAddressSubpage)
        }
    }

    return <Grid container flexDirection={"column"} height={"100%"} gap={1} flexWrap={"nowrap"}>
        <Grid item>
            <MDCard color={"white"} borderRadiusSize={"xxxl"} boxShadow={false}>
                <MDBox py={1}>
                    <OrderStepper steps={steps} activeStep={activeStep} maxStep={maxStep} 
                        onBack={(newStep?: number) => activeStep !== TemplateSteps.ReturnAddressSubpage ? onBack(newStep) : null}
                        onNext={(newStep?: number) => activeStep !== TemplateSteps.ReturnAddressSubpage ? onNext(newStep) : null}
                    />
                </MDBox>
            </MDCard>
        </Grid>

        <Grid item flex={1}>
            {activeStep === TemplateSteps.ProductSubpage &&
                <ProductSubpage
                    order={template}
                    setOrder={setTemplate}
                    showWarningModal={showWarningModal}
                    setShowWarningModal={setShowWarningModal}
                    product={product}
                    setProduct={setProduct}
                    onNext={onNext}
                    orderName={orderName}
                    setOrderName={setOrderName}
                />
            }

            {activeStep === TemplateSteps.DesignSubpage &&
                <PageSettingsProvider order={template}>
                    <DesignSubpage
                        order={template}
                        setOrder={setTemplate}
                        orderParameters={orderParameters}
                        setOrderParameters={setOrderParameters}
                        onBack={onBack}
                        onNext={onNext}
                        hasSceneWarnings={hasSceneWarnings}
                        setHasSceneWarnings={setHasSceneWarnings}
                        showErrorModal={showErrorModal}
                        setShowErrorModal={setShowErrorModal}
                    />
                </PageSettingsProvider>
            }

            {activeStep === TemplateSteps.ReturnAddressSubpage &&
                <PageSettingsProvider order={template}>
                    <ReturnAddressSubpage
                        order={template}
                        setOrder={setTemplate}
                        onBack={onBack}
                        onNext={onNext}
                    />
                </PageSettingsProvider>
            }

            {activeStep === TemplateSteps.ReviewSubpage &&
                <ReviewSubpage order={template} setActiveStep={setActiveStep}/>
            }
        </Grid>
    </Grid>
}

function TemplatePage() {
    const params = useParams()
    const id = parseInt(params.id)

    const {getTemplate} = TemplateService()

    const [template, setTemplate] = useState<Order>(null)

    const getTemplateQuery = useQuery({
        queryKey: ["template", id],
        queryFn: () => {
            return getTemplate(id)
        },
        refetchOnWindowFocus: false
    })

    useEffect(() => {
        if (getTemplateQuery.data) {
            setTemplate(getTemplateQuery.data[0])
        }
    }, [getTemplateQuery.data]);

    return <EditorProvider order={template} setOrder={setTemplate}>
        <Content template={template} setTemplate={setTemplate}/>
    </EditorProvider>
}

export default TemplatePage