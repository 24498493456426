import useFetchWithMsal from "hooks/useFetch";
import API_ENDPOINT from "../endpoint";
import { GridSearchParams } from "../../models/gridSearchParams";
import { EnrichmentType } from "models/enums/ProfileEnrichment";

function ProfileRecipientEnrichmentService() {
    const { execute } = useFetchWithMsal()

    const API_PATH = `${API_ENDPOINT}/ProfileEnrichment`

    function getEnrichOrderDetails(enrichmentOrderId: number) {
        return execute({
            endpoint: `${API_PATH}/${enrichmentOrderId}/enrichment-order-details`,
            method: "GET",
        })
    }

    function getEnrichCheckout(profileRecipientIds: number[], enrichBy: EnrichmentType, lastSearchParams: GridSearchParams, selectedAll: boolean) {
        let queryParams = `enrichBy=${enrichBy}`;
        queryParams += selectedAll ? `&selectedAll=${selectedAll}` : `&profileRecipientIds=${profileRecipientIds}`;
        if (lastSearchParams.filters) queryParams += `&filters=${encodeURIComponent(lastSearchParams.filters)}`;
        if (lastSearchParams.search) queryParams += `&search=${encodeURIComponent(lastSearchParams.search)}`;
        return execute({
            endpoint: `${API_PATH}/get-checkout?${queryParams}`,
            method: "GET",
        })
    }

    function postEnrichCheckout(profileRecipientIds: number[], enrichBy: EnrichmentType, lastSearchParams: object, selectedAll: boolean, paymentMethodId: string) {
        return execute({
            endpoint: `${API_PATH}`,
            method: "POST",
            data: {
                enrichBy,
                profileRecipientIds,
                lastSearchParams,
                selectedAll,
                paymentMethodId
            }
        })
    }

    function getProfileEnrichmentConflicts(enrichmentOrderId: number) {
        return execute({
            endpoint: `${API_PATH}/${enrichmentOrderId}`,
            method: "GET"
        })
    }

    function resolveProfileEnrichmentConflicts(enrichmentOrderId: number, resolved: any[]) {
        return execute({
            endpoint: `${API_PATH}/${enrichmentOrderId}/resolve-conflict`,
            method: 'POST',
            data: {
                ResolvedConflicts: resolved
            }
        })
    }

    return {
        getEnrichOrderDetails,
        getEnrichCheckout,
        postEnrichCheckout,
        getProfileEnrichmentConflicts,
        resolveProfileEnrichmentConflicts
    }
}

export default ProfileRecipientEnrichmentService