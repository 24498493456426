import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { PaymentMethod } from '@stripe/stripe-js';
import { CardElement, PaymentElement } from '@stripe/react-stripe-js';
import { Box, Collapse, FormControlLabel, Paper, Radio, RadioGroup, Skeleton, Stack, Typography } from '@mui/material';

import RadioSelectWrapper from 'components/radio-select-wrapper';
import ProfileService from 'services/profile';
import { ICheckout } from 'models/checkout';

interface SelectPaymentMethodProps {
  checkout?: ICheckout;
  selectedCard: PaymentMethod;
  setSelectedCard: (selectedCard: PaymentMethod) => void;
  setHasIncompleteCard: (value: boolean) => void;
}

export default function SelectPaymentMethod({
  checkout,
  selectedCard,
  setSelectedCard,
  setHasIncompleteCard,
}: SelectPaymentMethodProps) {
  const { getProfile } = ProfileService();
  const { data: profile } = useQuery({ queryKey: ['profile'], queryFn: () => getProfile() });

  useEffect(() => {
    if (!checkout?.savedPaymentMethods.length) return;
    let auxDefault = checkout.savedPaymentMethods.find((el) => profile?.defaultPaymentMethodId === el.id);
    auxDefault = auxDefault ?? checkout.savedPaymentMethods[0];
    setSelectedCard(auxDefault);
  }, [checkout?.savedPaymentMethods, profile?.defaultPaymentMethodId, setSelectedCard]);

  const billingName =
    profile && profile.firstName && profile.lastName ? `${profile.firstName} ${profile.lastName}` : ``;

  const cardBrands: { [key: string]: string } = {
    visa: 'https://js.stripe.com/v3/fingerprinted/img/visa-729c05c240c4bdb47b03ac81d9945bfe.svg',
    mastercard: 'https://js.stripe.com/v3/fingerprinted/img/mastercard-4d8844094130711885b5e41b28c9848f.svg',
    amex: 'https://js.stripe.com/v3/fingerprinted/img/amex-a49b82f46c5cd6a96a6e418a6ca1717c.svg',
  };

  return (
    <Box>
      <RadioGroup value={selectedCard} sx={{ flexWrap: 'nowrap', mb: 1 }}>
        <Stack spacing={1}>
          {checkout ? (
            <>
              <Box zIndex={20}>
                <RadioSelectWrapper selected={!selectedCard} onClick={() => setSelectedCard(null)} padding={'2px 10px'}>
                  <FormControlLabel control={<Radio />} value={null} label={'New payment info'} />
                </RadioSelectWrapper>
              </Box>
              <Collapse in={!selectedCard}>
                <Paper variant="outlined" sx={{ p: '16px 8px 4px', mt: '-20px' }}>
                  {checkout?.stripeToken ? (
                    <PaymentElement
                      onChange={(e) => setHasIncompleteCard(!e.complete)}
                      options={{ defaultValues: { billingDetails: { name: billingName, address: { country: 'US' } } } }}
                    />
                  ) : (
                    <CardElement onChange={(e) => setHasIncompleteCard(!e.complete)} />
                  )}
                </Paper>
              </Collapse>
              {checkout.savedPaymentMethods.map((pm) => (
                <RadioSelectWrapper
                  key={pm.id}
                  selected={selectedCard?.id === pm.id}
                  padding={'2px 10px'}
                  onClick={() => setSelectedCard(pm)}
                >
                  <FormControlLabel
                    value={pm}
                    control={<Radio />}
                    sx={{ display: 'flex', '.MuiFormControlLabel-label': { flex: 1 } }}
                    label={
                      <Box display={'flex'} flex={1} width={'100%'} justifyContent={'space-between'}>
                        <Typography variant="h5" width={'100%'} flex={1}>
                          **** {pm.card.last4}
                        </Typography>
                        <Typography variant="h5" mr={1}>
                          EXP {pm.card.exp_month}/{pm.card.exp_year}
                        </Typography>
                        {Object.keys(cardBrands).includes(pm.card.brand) && (
                          <img
                            alt={pm.card.brand}
                            src={cardBrands[pm.card.brand]}
                            style={{ width: 'auto', height: '20px' }}
                          />
                        )}
                      </Box>
                    }
                  />
                </RadioSelectWrapper>
              ))}
            </>
          ) : (
            <>
              <Skeleton width={'100%'} height={46} variant="rounded" />
              <Skeleton width={'100%'} height={46} variant="rounded" />
            </>
          )}
        </Stack>
      </RadioGroup>
    </Box>
  );
}
