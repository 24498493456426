import styled from 'styled-components';
import {ReactNode} from "react";
import colors from "material-ui/theme/base/colors";
import borders from "material-ui/theme/base/borders";

interface RadioSelectWrapperProps {
    selected?: boolean
    onClick?: Function
    children: ReactNode
    disabled?: boolean
    padding?: string
    backgroundColor?: "white" | "light"
}

const {primary} = colors
const {borderRadius} = borders

const StyledDiv = styled.div<RadioSelectWrapperProps>`
  padding: ${(props) => (props.padding ?? "10px")};
  width: 100%;
  height: 100%;
  background-color: ${(props) => (props.selected ? "#FEEFEA" : colors[props.backgroundColor].main)};
  border-radius: ${borderRadius.xl};
  border: 1px solid ${(props) => (props.selected ? primary.main : "#EDEFF5")};
  cursor: pointer;
  opacity: ${(props) => props.disabled? 0.5 : 1}
`;

function RadioSelectWrapper({selected = false, disabled = false, backgroundColor = "white", onClick, children, padding}: RadioSelectWrapperProps) {
    return <StyledDiv selected={selected} disabled={disabled} onClick={() => onClick? onClick() : () => {}} backgroundColor={backgroundColor} padding={padding}>
        {children}
    </StyledDiv>;
};

export default RadioSelectWrapper;