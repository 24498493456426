import React, {useEffect, useRef, useState} from "react";
import MDBox from "material-ui/components/MDBox";
import {
    Divider,
    Grid,
    Icon,
    Slider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    IconButton, Tooltip, CircularProgress
} from "@mui/material";
import MDButton from "material-ui/components/MDButton";
import {Coordinate} from "models/coordinate";
import {Circle, GoogleMap, LoadScript, OverlayView, Polygon as MapsPolygon} from "@react-google-maps/api";
import colors from "material-ui/theme/base/colors";
import InfoModal from "components/info-modal";
import {useParams} from "react-router-dom";
import {RecipientSearchStatus} from "models/recipient-search/recipientSearchStatus";
import {formatNumber, formatPrice} from "helpers/formatters";
import MDCard from "material-ui/components/MDCard";
import RadiusIcon from "assets/icons/radius";
import PolygonIcon from "assets/icons/polygon";
import MDInput from "material-ui/components/MDInput";
import DeleteIcon from "assets/icons/delete";
import {getAddressCoordinates, getCenterOfPolygon, getCoordinatesAddress, mapContainerStyle, mapInfoTextContainer} from "helpers/google-map-helper";
import MDTypography from "material-ui/components/MDTypography";
import Loader from "components/loader";
import {
    ServiceAreaPolygonSearch,
    ServiceAreaRadiusSearch,
    ServiceAreaRecipientSearch
} from "models/hail-trace/ServiceAreaRecipientSearch";
import AdminService from "services/admin";
import {OwnerOrRenterFilter, PropertyTypeFilter} from "models/radius-mailing/filters";
import CheckIcon from "assets/icons/check";
import HailSizeIcon from "assets/icons/hail-size";
import WindSpeedIcon from "assets/icons/wind-speed";
import HomeAlt2Icon from "assets/icons/home-alt-2";
import HomeEditIcon from "assets/icons/home-edit";
import CalendarIcon from "assets/icons/calendar";
import CardsToSendIcon from "assets/icons/cards-to-send";
import moment from "moment/moment";
import { useGlobal } from "context/global-context";

function SuperAdminStormAutomationManagePage() {
    const minimumQuantity: number = 1;
    const {setShowLoader} = useGlobal()
    const {getHailTraceRecipientSearch, approveHailTraceRecipientSearch, hailTraceRecipientSearchCalculateTargets, hailTraceRecipientSearchUpdatePricePerCard} = AdminService()

    const orderId = parseInt(useParams().id)

    const [map, setMap] = useState<google.maps.Map>(null)
    const mapOptions: google.maps.MapOptions = {
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeId: 'hybrid',
        disableDefaultUI: true
    }

    const [hasMapLoaded, setHasMapLoaded] = useState(false)

    const [center, setCenter] = useState<Coordinate>({lat: null, lng: null})
    const [zoom, setZoom] = useState<number>(7)

    const [serviceArea, setServiceArea] = useState<ServiceAreaRecipientSearch>(null)

    const [showSuccessfulCountFetchModal, setShowSuccessfulCountFetchModal] = useState<boolean>(false)
    const [successfulCountFetchText, setSuccessfulCountFetchText] = useState<string>(null)

    const [showApproveOrderModal, setShowApproveOrderModal] = useState<boolean>(false)
    const [showSuccessfulApprovalModal, setShowSuccessfulApprovalModal] = useState<boolean>(false)

    const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
    const [errorModalText, setErrorModalText] = useState<string>(null)

    const [geocoder, setGeocoder] = useState(null)
    const polygonsRef = useRef([])

    const [isAddingAddress, setIsAddingAddress] = useState<boolean>(false)
    const [isAddingPolygon, setIsAddingPolygon] = useState<boolean>(false)

    const [canUserFetchCount, setCanUserFetchCount] = useState<boolean>(false)
    const [canUserCheckout, setCanUserCheckout] = useState<boolean>(false)
    const [canUserInteractWithUI, setCanUserInteractWithUI] = useState<boolean>(false)

    const [isPriceBeingRecalculated, setIsPriceBeingRecalculated] = useState<boolean>(false)

    useEffect(function () {
        setShowLoader(true)

        if (orderId) {
            getHailTraceRecipientSearch(orderId).then((result) => {
                if (!result.hasErrors) {
                    let payload = result.payload as ServiceAreaRecipientSearch

                    if (payload.status === RecipientSearchStatus.ReadyForPayment) {
                        setCanUserCheckout(true)
                    }

                    if (payload.status === RecipientSearchStatus.ProcessingCount) {
                        startCountInterval(payload.id)
                    }

                    setServiceArea(payload)

                    setShowLoader(false)
                }
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(function () {
        if(!serviceArea){
            return
        }

        switch (serviceArea.status) {
            case RecipientSearchStatus.New:
                setCanUserCheckout(false)
                setCanUserInteractWithUI(true)

                if (!serviceArea.addressRadiusSearches.length && !serviceArea.polygonSearches.length) {
                    setCanUserFetchCount(false)
                }
                else {
                    let _a = true

                    for (let e of serviceArea.addressRadiusSearches) {
                        if (!e.payload.coordinate || !e.payload.radius || !e.payload.coordinate.lng || !e.payload.coordinate.lat) {
                            _a = false
                            break
                        }
                    }

                    setCanUserFetchCount(_a)
                }

                break
            case RecipientSearchStatus.ProcessingCount:
                setCanUserFetchCount(false)
                setCanUserCheckout(false)
                setCanUserInteractWithUI(false)

                break
            case RecipientSearchStatus.ReadyForPayment:
                setCanUserInteractWithUI(true)
                setCanUserFetchCount(false)

                let _a = true

                for(let e of serviceArea.addressRadiusSearches){
                    if (e.desiredQuantity < minimumQuantity || e.desiredQuantity > e.availableQuantity){
                        _a = false
                        break
                    }
                }

                for(let e of serviceArea.polygonSearches){
                    if (e.desiredQuantity < minimumQuantity || e.desiredQuantity > e.availableQuantity){
                        _a = false
                        break
                    }
                }

                setCanUserCheckout(_a)

                break
            case RecipientSearchStatus.Paid:
                setCanUserCheckout(false)
                setCanUserFetchCount(false)
                setCanUserInteractWithUI(false)

                break
            case RecipientSearchStatus.ProcessingOrder:
                setCanUserCheckout(false)
                setCanUserFetchCount(false)
                setCanUserInteractWithUI(false)

                break
            case RecipientSearchStatus.Complete:
                setCanUserCheckout(false)
                setCanUserFetchCount(false)
                setCanUserInteractWithUI(false)

                break
            case RecipientSearchStatus.Error:
                setCanUserCheckout(false)
                setCanUserFetchCount(true)
                setCanUserInteractWithUI(true)
                break
            case RecipientSearchStatus.UnexpectedError:
                setCanUserCheckout(false)
                setCanUserFetchCount(true)
                setCanUserInteractWithUI(true)
                setErrorModalText('An unexpected error occurred. Please try again or contact us at billing@lettrlabs.com')
                setShowErrorModal(true)

                break
            default:
                break
        }
    }, [serviceArea])

    useEffect(() => {
        if (map && serviceArea && serviceArea.polygonSearches.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();

            serviceArea.polygonSearches.forEach(polygon => {
              polygon.payload.coordinates.forEach(coord => {
                bounds.extend(new window.google.maps.LatLng(coord.lat, coord.lng));
              });
            });
        
            map.fitBounds(bounds);
            map.setZoom(12)
        }
    }, [map, serviceArea]);

    function onPolygonLoad(polygon: google.maps.Polygon, id: number) {
        polygonsRef.current.push({id: id, polygon: polygon})
    }

    function onPolygonEdit(id: number) {
        let polygonToEdit = (polygonsRef.current.find((e: any) => e.id === id)).polygon

        let updatedPath = polygonToEdit.getPath().getArray().map((coordinate: google.maps.LatLng) => {
            return {lat: coordinate.lat(), lng: coordinate.lng()}
        })

        setServiceArea(prevState => {
            let copy = {...prevState}

            copy.status = RecipientSearchStatus.New

            for (let el of copy.polygonSearches) {
                if (el.id === id) {
                    el.payload.coordinates = updatedPath
                    break
                }
            }

            return copy
        })

        setCanUserCheckout(false)
    }

    function addPolygon(lat: number, lng: number, addressData: any) {
        setServiceArea(prevState => {
            let copy = {...prevState}

            copy.status = RecipientSearchStatus.New

            let sortedPolygons = copy.polygonSearches.sort((a, b) => {
                if (a.id > b.id) {
                    return 1
                } else if (a.id < b.id) {
                    return -1
                }
                return 0
            })

            let lastId = sortedPolygons.length ? sortedPolygons[sortedPolygons.length - 1].id : 0

            let offset = 0.02898550724 //Approximate offset for the 4 points around the center (2 miles)

            let newPolygon: ServiceAreaPolygonSearch = {
                id: lastId + 1,
                name: addressData.shortAddress,
                availableQuantity: 0,
                desiredQuantity: 0,
                price: null,
                totalPrice: null,
                status: RecipientSearchStatus.New,
                payload: {
                    coordinates: [
                        {lat: lat, lng: lng - offset},
                        {lat: lat - offset, lng: lng},
                        {lat: lat, lng: lng + offset},
                        {lat: lat + offset, lng: lng},
                    ]
                },
                radiusPinAddress: "",
                radiusPinStreet: "",
                isNew: true
            }

            copy.polygonSearches.push(newPolygon)

            return copy
        })
    }

    function addAddress(lat: number, lng: number, addressData: any) {
        setServiceArea(prevState => {
            let copy = {...prevState}

            copy.status = RecipientSearchStatus.New

            let sortedAddresses = copy.addressRadiusSearches.sort((a, b) => {
                if (a.id > b.id) {
                    return 1
                } else if (a.id < b.id) {
                    return -1
                }
                return 0
            })

            let lastId = sortedAddresses.length ? sortedAddresses[sortedAddresses.length - 1].id : 0

            let newAddress: ServiceAreaRadiusSearch = {
                id: lastId + 1,
                address: addressData.shortAddress,
                availableQuantity: 0,
                desiredQuantity: 0,
                price: null,
                totalPrice: null,
                status: RecipientSearchStatus.New,
                isNew: true,
                radiusPinAddress: addressData.shortAddress,
                radiusPinStreet: addressData.streetName,
                payload: {
                    coordinate: {
                        lat: lat,
                        lng: lng
                    },
                    radius: 2,
                }
            }

            copy.addressRadiusSearches.push(newAddress)

            return copy
        })
    }

    function onMapLoaded() {
        setHasMapLoaded(true)

        setGeocoder(new window.google.maps.Geocoder())

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                function (pos: GeolocationPosition) {
                    let lat = pos.coords.latitude
                    let lng = pos.coords.longitude

                    setCenter({lat, lng})
                    setZoom(7)
                },
                function (error) {
                    setCenter({lat: 33.824386, lng: -118.1358666})
                    setZoom(10)
                })
        } else {
            setCenter({lat: 33.824386, lng: -118.1358666})
            setZoom(10)
        }
    }

    function deleteAddress(id: number) {
        setServiceArea(prevState => {
            let copy = {...prevState}

            copy.status = RecipientSearchStatus.New

            copy.addressRadiusSearches = copy.addressRadiusSearches.filter((el) => el.id !== id)

            return copy
        })
    }

    function deletePolygon(id: number) {
        setServiceArea(prevState => {
            let copy = {...prevState}

            copy.status = RecipientSearchStatus.New

            copy.polygonSearches = copy.polygonSearches.filter((el) => el.id !== id)

            return copy
        })
    }

    function getPolygonPrice(entry: ServiceAreaPolygonSearch) {
        if(entry.price) {
           return parseFloat(entry.price.toFixed(2))
        }
    }

    function getPolygonTotalPrice(entry: ServiceAreaPolygonSearch) {
        if(entry.totalPrice) {
           return parseFloat(entry.totalPrice.toFixed(2))
        }
    }

    function getAddressPrice(entry: ServiceAreaRadiusSearch) {
        if(entry.price){
            return parseFloat(entry.price.toFixed(2))
        }
        return 0
    }

    function getAddressTotalPrice(entry: ServiceAreaRadiusSearch) {
        if(entry.totalPrice){
            return parseFloat(entry.totalPrice.toFixed(2))
        }
        return 0
    }

    function fetchCount() {
        setShowLoader(true)

        let postData = JSON.parse(JSON.stringify(serviceArea));

        for (let e of postData.addressRadiusSearches) {
            if (e.isNew) {
                e.id = 0
            }
        }

        for (let e of postData.polygonSearches) {
            if (e.isNew) {
                e.id = 0
            }
        }

        hailTraceRecipientSearchCalculateTargets(postData).then((result1) => {
            if (result1.hasErrors) {
                setErrorModalText(result1.errors[0])
                setShowErrorModal(true)

                setShowLoader(false)

                return
            }

            let payload = result1.payload as ServiceAreaRecipientSearch

            setServiceArea({...payload, recipientSearchFilter: serviceArea.recipientSearchFilter})

            startCountInterval(payload.id)
        })
    }

    function startCountInterval(serviceAreaId: number) {
        setShowLoader(true)

        let interval = setInterval(function () {
            getHailTraceRecipientSearch(serviceAreaId).then((result2) => {
                if (!result2) {
                    setErrorModalText('An unexpected error occurred. Please try again or contact us at billing@lettrlabs.com')
                    setShowErrorModal(true)

                    clearInterval(interval)
                    return
                }
                if(!serviceArea){
                    return
                }
                setServiceArea({
                    ...result2.payload,
                    recipientSearchFilter: serviceArea.recipientSearchFilter
                })

                if (!result2.hasErrors) {
                    if (result2.payload.status === RecipientSearchStatus.ReadyForPayment) {
                        clearInterval(interval)
                        setShowLoader(false)

                        setSuccessfulCountFetchText(`Your order has returned a total of ${result2.payload.totalAvailableQuantity ? formatNumber(result2.payload.totalAvailableQuantity) : '-'} addresses`)
                        setShowSuccessfulCountFetchModal(true)
                    }
                    if (result2.payload.status === RecipientSearchStatus.Error) {
                        clearInterval(interval)
                        setShowLoader(false)
                    }
                    if (result2.payload.status === RecipientSearchStatus.UnexpectedError) {
                        clearInterval(interval)
                        setShowLoader(false)

                        setErrorModalText('An unexpected error occurred. Please try again or contact us at billing@lettrlabs.com')
                        setShowErrorModal(true)
                    }
                } else {
                    setErrorModalText(result2.errors[0])
                    setShowErrorModal(true)

                    clearInterval(interval)
                }
            })
        }, 5000)
    }

    function approveOrder() {
        setShowLoader(true)

        let postData = {...serviceArea}

        for (let e of postData.addressRadiusSearches) {
            if (e.isNew) {
                e.id = 0
            }
        }

        for (let e of postData.polygonSearches) {
            if (e.isNew) {
                e.id = 0
            }
        }

        approveHailTraceRecipientSearch(postData).then(() => {
            setShowLoader(false)

            setShowApproveOrderModal(false)

            setShowSuccessfulApprovalModal(true)
        })
    }

    function updatePricePerCard(){
        setIsPriceBeingRecalculated(true)

        hailTraceRecipientSearchUpdatePricePerCard(serviceArea).then((result: any) => {
            let newServiceArea = result.payload as ServiceAreaRecipientSearch

            setServiceArea(prevState => {
                return {
                    ...prevState,
                    polygonSearches: newServiceArea.polygonSearches,
                    addressRadiusSearches: newServiceArea.addressRadiusSearches
                }
            })

            setIsPriceBeingRecalculated(false)
        })
    }

    return serviceArea? (<MDBox>
        <Grid container gap={3}>
            <Grid item flex={1} maxWidth={"700px"}>
                <MDCard>
                    <MDBox p={3}>
                        <MDCard border={true} borderRadiusSize={"xl"} borderColor={"light"} boxShadow={false}>
                            <MDBox p={2}>
                                <Typography sx={{fontSize: 24}} fontWeight={"bold"} color={"secondary"}>Manage Service Area</Typography>
                                <Typography sx={{fontSize: 18}} fontWeight={"bold"} color={"secondary"}>Id: {serviceArea.id}</Typography>
                                <Typography sx={{fontSize: 18}} fontWeight={"bold"} color={"secondary"}>Customer: {serviceArea.customerEmail}</Typography>

                                <Divider light={true}/>

                                <Grid container mt={3} gap={2}>
                                    <Grid item>
                                        <MDButton
                                            color="primary"
                                            onClick={() => setIsAddingAddress(true)}
                                        >
                                            <Grid container alignItems={"center"} justifyContent={"center"} gap={1}>
                                                <Grid item display={"flex"}>
                                                    <RadiusIcon/>
                                                </Grid>
                                                <Grid item>
                                                    Radius
                                                </Grid>
                                            </Grid>
                                        </MDButton>
                                    </Grid>

                                    <Grid item>
                                        <MDButton
                                            color="primary"
                                            onClick={() => setIsAddingPolygon(true)}
                                        >
                                            <Grid container alignItems={"center"} justifyContent={"center"} gap={1}>
                                                <Grid item display={"flex"}>
                                                    <PolygonIcon/>
                                                </Grid>
                                                <Grid item>
                                                    Polygon
                                                </Grid>
                                            </Grid>
                                        </MDButton>
                                    </Grid>
                                </Grid>

                                <MDBox mt={3}>
                                    {serviceArea.addressRadiusSearches.map((entry, index) => {
                                        return <MDCard sx={{marginBottom: "25px"}} key={index} border={true} borderColor={"light"} boxShadow={false} borderRadiusSize={"lg"}>
                                            <MDBox p={1} pt={2}>
                                                <Grid container pl={1} mb={4} alignItems={"center"} justifyContent={"space-between"} flexWrap={"nowrap"} flexDirection={'row'}>
                                                    <Grid item>
                                                        <Typography fontWeight={"bold"} sx={{fontSize: 18}}>
                                                            {entry.address}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item display={"flex"}>
                                                        <Grid container alignItems={"center"} justifyContent={"center"} flexWrap={"nowrap"} gap={"10px"} flexDirection={"row"}>
                                                            <Grid item display={"flex"} flexWrap={"nowrap"}>
                                                                <Tooltip title={"Go to Address"}>
                                                                    <IconButton color={"primary"} sx={{padding: 0}}
                                                                                disabled={!entry.payload.coordinate.lat || !entry.payload.coordinate.lng}
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation()
                                                                                    setCenter({
                                                                                        lat: entry.payload.coordinate.lat,
                                                                                        lng: entry.payload.coordinate.lng
                                                                                    })
                                                                                    setZoom(12)
                                                                                }}>
                                                                        <Icon sx={{cursor: "pointer"}}
                                                                              fontSize={"medium"}>my_location</Icon>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>

                                                            <Grid item display={"flex"} flexWrap={"nowrap"}>
                                                                <Tooltip title={"Delete Address"} onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    deleteAddress(entry.id)
                                                                }}>
                                                                    <IconButton color={"error"} sx={{padding: 0}}>
                                                                        <DeleteIcon/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <MDBox mt={2} pb={1}>
                                                    <Grid container gap={"20px"}>
                                                        <Grid item flex={1}>
                                                            <MDInput
                                                                fullWidth={true}
                                                                type="text"
                                                                label="Address"
                                                                value={entry.address}
                                                                InputLabelProps={{shrink: true}}
                                                                onChange={(event: any) => {
                                                                    setServiceArea(prevState => {
                                                                        let copy = {...prevState}

                                                                        copy.status = RecipientSearchStatus.New

                                                                        for (let e of copy.addressRadiusSearches) {
                                                                            if (e.id === entry.id) {
                                                                                e.address = event.target.value

                                                                                break
                                                                            }
                                                                        }

                                                                        return copy
                                                                    })

                                                                    getAddressCoordinates(geocoder, event.target.value, function (lat: number, lng: number) {
                                                                        if (lat && lng) {
                                                                            setCenter({lat, lng})
                                                                        }

                                                                        getCoordinatesAddress(geocoder, lat, lng, (addressData: any) => {
                                                                            setServiceArea(prevState => {
                                                                                let copy = {...prevState}

                                                                                copy.status = RecipientSearchStatus.New

                                                                                for (let e of copy.addressRadiusSearches) {
                                                                                    if (e.id === entry.id) {
                                                                                        e.payload.coordinate = {
                                                                                            lat, lng
                                                                                        }
                                                                                        e.radiusPinAddress = addressData.shortAddress
                                                                                        e.radiusPinStreet = addressData.streetName

                                                                                        break
                                                                                    }
                                                                                }

                                                                                return copy
                                                                            })
                                                                        })
                                                                    })
                                                                }}
                                                            />
                                                        </Grid>

                                                        <Grid item>
                                                            <MDInput
                                                                fullWidth={true}
                                                                type="number"
                                                                inputProps={{
                                                                    step: 0.1
                                                                }}
                                                                label="Radius (miles)"
                                                                value={entry.payload.radius}
                                                                InputLabelProps={{shrink: true}}
                                                                onChange={(event: any) => {
                                                                    setServiceArea(prevState => {
                                                                        let copy = {...prevState}

                                                                        copy.status = RecipientSearchStatus.New

                                                                        let miles = parseFloat(event.target.value)

                                                                        if (miles > 30) {
                                                                            miles = 30
                                                                        }
                                                                        if (miles < 0) {
                                                                            miles = 0
                                                                        }

                                                                        for (let e of copy.addressRadiusSearches) {
                                                                            if (e.id === entry.id) {
                                                                                e.payload.radius = miles
                                                                                break
                                                                            }
                                                                        }

                                                                        return copy
                                                                    })
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>

                                                    {entry.availableQuantity < minimumQuantity &&
                                                        <MDBox mt={3} pl={2} pr={2}>
                                                            <Typography>
                                                                Not return enough results - please widen the search
                                                            </Typography>
                                                        </MDBox>
                                                    }

                                                    {entry.availableQuantity && entry.availableQuantity >= minimumQuantity?
                                                        <MDBox mt={3} pl={2}>
                                                            <Grid container alignItems={"center"} justifyContent={"space-between"} gap={"20px"}>
                                                                <Grid item flex={1}>
                                                                    <Grid container alignItems={"center"} justifyContent={"center"} gap={2}>
                                                                        <Grid item>
                                                                            <Typography>{minimumQuantity}</Typography>
                                                                        </Grid>
                                                                        <Grid item display={"flex"} flex={1}>
                                                                            <Slider
                                                                                min={minimumQuantity}
                                                                                max={entry.availableQuantity}
                                                                                valueLabelDisplay="auto"
                                                                                disabled={!canUserInteractWithUI}
                                                                                value={entry.desiredQuantity}
                                                                                valueLabelFormat={(value) => {
                                                                                    return value ? formatNumber(value) : '-'
                                                                                }}
                                                                                onChange={(event, value: any) => {
                                                                                    setServiceArea(prevState => {
                                                                                        let copy = {...prevState}

                                                                                        for (let e of copy.addressRadiusSearches) {
                                                                                            if (e.id === entry.id) {
                                                                                                e.desiredQuantity = value
                                                                                                break
                                                                                            }
                                                                                        }

                                                                                        return copy
                                                                                    })
                                                                                }}
                                                                                onChangeCommitted={() => {
                                                                                    updatePricePerCard()
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Typography>{entry.availableQuantity ? formatNumber(entry.availableQuantity) : '-'}</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid item maxWidth={"180px"}>
                                                                    <MDInput
                                                                        fullWidth={true}
                                                                        label="Desired Quantity"
                                                                        error={entry.desiredQuantity < minimumQuantity || entry.desiredQuantity > entry.availableQuantity}
                                                                        value={entry.desiredQuantity ? formatNumber(entry.desiredQuantity) : '-'}
                                                                        InputLabelProps={{shrink: true}}
                                                                        onChange={(event: any) => {
                                                                            let val = event.target.value

                                                                            val = val.replaceAll(",", "").replaceAll(".", "")

                                                                            val = parseInt(val)

                                                                            if(!val || isNaN(val)) {
                                                                                val = 0
                                                                            }

                                                                            setServiceArea(prevState => {
                                                                                let copy = {...prevState}

                                                                                for (let e of copy.addressRadiusSearches) {
                                                                                    if (e.id === entry.id) {
                                                                                        e.desiredQuantity = val
                                                                                        break
                                                                                    }
                                                                                }

                                                                                return copy
                                                                            })
                                                                        }}
                                                                        onBlur={() => {
                                                                            updatePricePerCard()
                                                                        }}
                                                                    />

                                                                    {entry.desiredQuantity < minimumQuantity || entry.desiredQuantity > entry.availableQuantity?
                                                                        <MDTypography
                                                                            variant={"h6"}
                                                                            fontWeight={"regular"}
                                                                            color="error"
                                                                        >
                                                                            Quantity must be between {entry.availableQuantity < minimumQuantity ? formatNumber(entry.availableQuantity) : minimumQuantity} and {entry.availableQuantity ? formatNumber(entry.availableQuantity) : '-'}
                                                                        </MDTypography>
                                                                        :
                                                                        null
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </MDBox>
                                                        :
                                                        null
                                                    }
                                                </MDBox>
                                            </MDBox>
                                        </MDCard>
                                    })}

                                    {serviceArea.polygonSearches.length ?
                                        serviceArea.polygonSearches.sort((a, b) => {
                                            if (a.id > b.id) {
                                                return 1
                                            } else if (a.id < b.id) {
                                                return -1
                                            }
                                            return 0
                                        }).map((entry, index) => {
                                            return <MDCard sx={{marginBottom: "25px"}} key={index} border={true} borderColor={"light"} boxShadow={false} borderRadiusSize={"lg"}>
                                                <MDBox p={1} pt={2} pb={2}>
                                                    <Grid container pl={1} alignItems={"center"} justifyContent={"space-between"} flexDirection={"row"} flexWrap={"nowrap"}>
                                                        <Grid item>
                                                            <Typography fontWeight={"bold"} sx={{fontSize: 18}}>
                                                                {entry.name}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item display={"flex"}>
                                                            <Grid container alignItems={"center"} justifyContent={"center"} gap={"10px"} flexDirection={"row"} flexWrap={"nowrap"}>
                                                                <Grid item display={"flex"} flexWrap={"nowrap"}>
                                                                    <Tooltip title={"Go to Polygon"}>
                                                                        <IconButton color={"primary"} sx={{padding: 0}}
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation()

                                                                                        setCenter(getCenterOfPolygon(entry.payload.coordinates))

                                                                                        setZoom(12)
                                                                                    }}>
                                                                            <Icon sx={{cursor: "pointer"}}
                                                                                  fontSize={"medium"}>my_location</Icon>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Grid>

                                                                <Grid item display={"flex"} flexWrap={"nowrap"}>
                                                                    <Tooltip title={"Delete Polygon"} onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        deletePolygon(entry.id)
                                                                    }}>
                                                                        <IconButton color={"error"} sx={{padding: 0}}>
                                                                            <DeleteIcon/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    {entry.availableQuantity < minimumQuantity &&
                                                        <MDBox mt={3} pl={2} pr={2}>
                                                            <Typography>
                                                                Not return enough results - please widen the search
                                                            </Typography>
                                                        </MDBox>
                                                    }

                                                    {entry.availableQuantity && entry.availableQuantity >= minimumQuantity?
                                                        <MDBox mt={3} pl={2}>
                                                            <Grid container alignItems={"center"}
                                                                  justifyContent={"center"} gap={"20px"}>
                                                                <Grid item flex={1}>
                                                                    <Grid container alignItems={"center"}
                                                                          justifyContent={"center"} gap={"20px"}>
                                                                        <Grid item>
                                                                            <Typography>{minimumQuantity}</Typography>
                                                                        </Grid>
                                                                        <Grid item display={"flex"} flex={1}>
                                                                            <Slider
                                                                                min={minimumQuantity}
                                                                                max={entry.availableQuantity}
                                                                                valueLabelDisplay="auto"
                                                                                disabled={!canUserInteractWithUI}
                                                                                value={entry.desiredQuantity}
                                                                                valueLabelFormat={(value) => {
                                                                                    return value ? formatNumber(value) : '-'
                                                                                }}
                                                                                onChange={(event, value: any) => {
                                                                                    setServiceArea(prevState => {
                                                                                        let copy = {...prevState}

                                                                                        for (let e of copy.polygonSearches) {
                                                                                            if (e.id === entry.id) {
                                                                                                e.desiredQuantity = value
                                                                                                break
                                                                                            }
                                                                                        }

                                                                                        return copy
                                                                                    })
                                                                                }}
                                                                                onChangeCommitted={() => {
                                                                                    updatePricePerCard()
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Typography>{entry.availableQuantity ? formatNumber(entry.availableQuantity) : '-'}</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid item maxWidth={"180px"}>
                                                                    <MDInput
                                                                        fullWidth={true}
                                                                        label="Desired Quantity"
                                                                        error={entry.desiredQuantity < minimumQuantity || entry.desiredQuantity > entry.availableQuantity}
                                                                        value={entry.desiredQuantity ? formatNumber(entry.desiredQuantity) : '-'}
                                                                        InputLabelProps={{shrink: true}}
                                                                        onChange={(event: any) => {
                                                                            let val = event.target.value

                                                                            val = val.replaceAll(",", "").replaceAll(".", "")

                                                                            val = parseInt(val)

                                                                            if(!val || isNaN(val)) {
                                                                                val = 0
                                                                            }

                                                                            setServiceArea(prevState => {
                                                                                let copy = {...prevState}

                                                                                for (let e of copy.polygonSearches) {
                                                                                    if (e.id === entry.id) {
                                                                                        e.desiredQuantity = val
                                                                                        break
                                                                                    }
                                                                                }

                                                                                return copy
                                                                            })
                                                                        }}
                                                                        onBlur={() => {
                                                                            updatePricePerCard()
                                                                        }}
                                                                    />

                                                                    {entry.desiredQuantity < minimumQuantity || entry.desiredQuantity > entry.availableQuantity?
                                                                        <MDTypography
                                                                            variant={"h6"}
                                                                            fontWeight={"regular"}
                                                                            color="error"
                                                                        >
                                                                            Quantity must be between {entry.availableQuantity < minimumQuantity ? formatNumber(entry.availableQuantity) : minimumQuantity} and {entry.availableQuantity ? formatNumber(entry.availableQuantity) : '-'}
                                                                        </MDTypography>
                                                                        :
                                                                        null
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </MDBox>
                                                        :
                                                        null
                                                    }
                                                </MDBox>
                                            </MDCard>
                                        })
                                        :
                                        null
                                    }
                                </MDBox>

                                <MDBox my={3}>
                                    <Divider light={true}/>
                                </MDBox>

                                {serviceArea.hailTraceProfileServiceArea.template?
                                    <MDBox>
                                        <Grid container p={2} gap={1} flexWrap={"nowrap"}>
                                            <Grid item xs={6}>
                                                <MDCard sx={{
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    flex: 1
                                                }} borderRadiusSize={0} border={true}
                                                        borderColor={"darkest"} borderSize={2}
                                                        boxShadow={true} boxShadowSize={"large"}>
                                                    <img alt={""} style={{width: "100%", height: "auto"}}
                                                         src={serviceArea.hailTraceProfileServiceArea.template.sceneFrontThumb}/>
                                                </MDCard>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <MDCard sx={{
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    flex: 1
                                                }} borderRadiusSize={0} border={true}
                                                        borderColor={"darkest"} borderSize={2}
                                                        boxShadow={true} boxShadowSize={"large"}>
                                                    <img alt={""} style={{width: "100%", height: "auto"}}
                                                         src={serviceArea.hailTraceProfileServiceArea.template.sceneBackThumb}/>
                                                </MDCard>
                                            </Grid>
                                        </Grid>

                                        <Divider light={true}/>
                                    </MDBox>
                                    :
                                    null
                                }

                                <MDBox>
                                    <Typography sx={{fontSize: 18}} fontWeight={"bold"} color={"secondary"}>Filters</Typography>

                                    <Grid container mt={2} alignItems={"center"} gap={0.5}>
                                        <Grid item display={"flex"}>
                                            <HailSizeIcon/>
                                        </Grid>
                                        <Grid item>
                                            <MDTypography fontWeight={"bold"} sx={{fontSize: 15}} color={"secondary"}>
                                                Hail Size:
                                            </MDTypography>
                                        </Grid>
                                        <Grid item>
                                            <MDTypography sx={{fontSize: 15}}>
                                                {serviceArea.hailTraceProfileServiceArea.hailSizeMinFilter? `${serviceArea.hailTraceProfileServiceArea.hailSizeMinFilter}" and larger` : '-'}
                                            </MDTypography>
                                        </Grid>
                                    </Grid>

                                    <Grid container mt={1} alignItems={"center"} gap={0.5}>
                                        <Grid item display={"flex"}>
                                            <WindSpeedIcon/>
                                        </Grid>

                                        <Grid item>
                                            <MDTypography fontWeight={"bold"} sx={{fontSize: 15}} color={"secondary"}>
                                                Wind Speed:
                                            </MDTypography>
                                        </Grid>

                                        <Grid item>
                                            <MDTypography sx={{fontSize: 16}}>
                                                {serviceArea.hailTraceProfileServiceArea.windSpeedMinFilter? `${serviceArea.hailTraceProfileServiceArea.windSpeedMinFilter} MPH+` : '-'}
                                            </MDTypography>
                                        </Grid>
                                    </Grid>

                                    <Grid container mt={1} alignItems={"center"} gap={0.5}>
                                        <Grid item display={"flex"}>
                                            <HomeAlt2Icon/>
                                        </Grid>

                                        <Grid item>
                                            <MDTypography fontWeight={"bold"} sx={{fontSize: 15}} color={"secondary"}>
                                                Home Owner:
                                            </MDTypography>
                                        </Grid>

                                        <Grid item>
                                            <MDTypography sx={{fontSize: 15}}>
                                                {serviceArea.hailTraceProfileServiceArea.homeOwnerFilter !== null ? OwnerOrRenterFilter[serviceArea.hailTraceProfileServiceArea.homeOwnerFilter] : '-'}
                                            </MDTypography>
                                        </Grid>
                                    </Grid>

                                    <Grid container mt={1} alignItems={"center"} gap={0.5}>
                                        <Grid item display={"flex"}>
                                            <HomeEditIcon/>
                                        </Grid>

                                        <Grid item>
                                            <MDTypography fontWeight={"bold"} sx={{fontSize: 15}} color={"secondary"}>
                                                Property Type:
                                            </MDTypography>
                                        </Grid>

                                        <Grid item>
                                            <MDTypography sx={{fontSize: 15}}>
                                                {serviceArea.hailTraceProfileServiceArea.propertyTypeFilter !== null ? PropertyTypeFilter[serviceArea.hailTraceProfileServiceArea.propertyTypeFilter] : '-'}
                                            </MDTypography>
                                        </Grid>
                                    </Grid>

                                    <Grid container mt={1} alignItems={"center"} gap={0.5}>
                                        <Grid item display={"flex"}>
                                            <CardsToSendIcon/>
                                        </Grid>

                                        <Grid item>
                                            <MDTypography fontWeight={"bold"} sx={{fontSize: 15}} color={"secondary"}>
                                                Cards sent per storm max:
                                            </MDTypography>
                                        </Grid>

                                        <Grid item>
                                            <MDTypography sx={{fontSize: 15}}>
                                                {serviceArea.hailTraceProfileServiceArea.cardsToSendPerStormMax ? formatNumber(serviceArea.hailTraceProfileServiceArea.cardsToSendPerStormMax) : '-'}
                                            </MDTypography>
                                        </Grid>
                                    </Grid>

                                    <Grid container mt={1} alignItems={"center"} gap={0.5}>
                                        <Grid item display={"flex"}>
                                            <CalendarIcon/>
                                        </Grid>

                                        <Grid item>
                                            <MDTypography fontWeight={"bold"} sx={{fontSize: 15}} color={"secondary"}>
                                                Date:
                                            </MDTypography>
                                        </Grid>

                                        <Grid item>
                                            <MDTypography sx={{fontSize: 15}}>
                                                {serviceArea.hailTraceProfileServiceArea.startSendingAtDate?
                                                    `${moment(serviceArea.hailTraceProfileServiceArea.startSendingAtDate).format("MM/DD")} ${serviceArea.hailTraceProfileServiceArea.stopSendingAtDate ? ` - ${moment(serviceArea.hailTraceProfileServiceArea.stopSendingAtDate).format("MM/DD")}` : ''}` : '-'}
                                            </MDTypography>
                                        </Grid>
                                    </Grid>

                                    <Grid container mt={1} alignItems={"center"} gap={0.5}>
                                        <Grid item display={"flex"}>
                                            <CardsToSendIcon/>
                                        </Grid>

                                        <Grid item>
                                            <MDTypography fontWeight={"bold"} sx={{fontSize: 15}} color={"secondary"}>
                                                Re-Sending:
                                            </MDTypography>
                                        </Grid>

                                        <Grid item>
                                            <MDTypography sx={{fontSize: 15}}>
                                                {serviceArea.hailTraceProfileServiceArea.resendAfterWeeks? `${serviceArea.hailTraceProfileServiceArea.resendAfterWeeks} weeks` : '-'}
                                            </MDTypography>
                                        </Grid>
                                    </Grid>
                                </MDBox>

                                <MDBox mt={5}>
                                    <MDButton
                                        fullWidth={true}
                                        color="primary"
                                        circular={true}
                                        onClick={fetchCount}
                                        disabled={!canUserFetchCount}
                                    >
                                        Calculate Targets
                                    </MDButton>
                                </MDBox>
                            </MDBox>
                        </MDCard>
                    </MDBox>
                </MDCard>
            </Grid>

            <Grid item flex={1}>
                <MDBox display={"flex"} alignItems={"center"} justifyContent={"center"} sx={{height: "700px"}}>
                    <LoadScript googleMapsApiKey="AIzaSyAnZBcPxYjFTJ2p5VykxLnOcqM0gY4uIY0" onLoad={onMapLoaded} loadingElement={<Loader/>}>
                        {hasMapLoaded &&
                            <GoogleMap
                                onLoad={(map) => {
                                    setMap(map)
                                }}
                                mapContainerStyle={mapContainerStyle}
                                center={center}
                                zoom={zoom}
                                onZoomChanged={() => {
                                    if (map) {
                                        setZoom(map.getZoom())
                                    }
                                }}
                                options={mapOptions}
                                onClick={(e: google.maps.MapMouseEvent) => {
                                    let lat = e.latLng.lat()
                                    let lng = e.latLng.lng()

                                    if (isAddingAddress) {
                                        getCoordinatesAddress(geocoder, lat, lng, (addressData: any) => {
                                            addAddress(e.latLng.lat(), e.latLng.lng(), addressData)
                                        })

                                        setIsAddingAddress(false)
                                    }
                                    if (isAddingPolygon) {
                                        getCoordinatesAddress(geocoder, lat, lng, (addressData: any) => {
                                            addPolygon(lat, lng, addressData)
                                        })

                                        setIsAddingPolygon(false)
                                    }
                                }}
                            >
                                <MDBox sx={{
                                    ...mapInfoTextContainer,
                                    transform: isAddingAddress ? "translateY(0px)" : "translateY(50px)"
                                }}>
                                    <MDTypography sx={{fontSize: 17}} fontWeight={"normal"} color={"white"}>
                                        Click anywhere on the map to add an address
                                    </MDTypography>
                                </MDBox>

                                <MDBox sx={{
                                    ...mapInfoTextContainer,
                                    transform: isAddingPolygon ? "translateY(0px)" : "translateY(50px)"
                                }}>
                                    <MDTypography sx={{fontSize: 17}} fontWeight={"normal"} color={"white"}>
                                        Click anywhere on the map to add a polygon
                                    </MDTypography>
                                </MDBox>

                                <MDCard border={true} borderColor={"light"} boxShadow={false} borderRadiusSize={"lg"} sx={{position: "absolute", top: "10px", left: "10px"}}>
                                    <MDBox p={0.5} pl={1}>
                                        <Grid container alignItems={"center"} gap={1}>
                                            <Grid item>
                                                <div style={{width: "8px", height: "8px", backgroundColor: colors.primary.main}}></div>
                                            </Grid>

                                            <Grid item>
                                                <MDTypography sx={{fontSize: 15}}>Actual Recipient Search</MDTypography>
                                            </Grid>
                                        </Grid>

                                        <Grid container alignItems={"center"} gap={1}>
                                            <Grid item>
                                                <div style={{width: "8px", height: "8px", backgroundColor: colors.info.main}}></div>
                                            </Grid>

                                            <Grid item>
                                                <MDTypography sx={{fontSize: 15}}>Area of the Storm</MDTypography>
                                            </Grid>
                                        </Grid>

                                        <Grid container alignItems={"center"} gap={1}>
                                            <Grid item>
                                                <div style={{width: "8px", height: "8px", backgroundColor: colors.error.main}}></div>
                                            </Grid>

                                            <Grid item>
                                                <MDTypography sx={{fontSize: 15}}>Service Area</MDTypography>
                                            </Grid>
                                        </Grid>
                                    </MDBox>
                                </MDCard>

                                {serviceArea.addressRadiusSearches.filter((a) => a.payload.coordinate.lat && a.payload.coordinate.lng).map((address, index) => {
                                    return <MDBox key={index}>
                                        <OverlayView
                                            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                            getPixelPositionOffset={(width, height) => ({
                                                x: -(width / 2),
                                                y: -(height / 2),
                                            })}
                                            position={{
                                                lat: address.payload.coordinate.lat,
                                                lng: address.payload.coordinate.lng
                                            }}
                                        >
                                            <Typography whiteSpace={"nowrap"} fontWeight={"bold"} textAlign={"center"}
                                                        color={colors.white.main}
                                                        sx={{textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black"}}>{address.address}</Typography>
                                        </OverlayView>

                                        <Circle
                                            center={{
                                                lat: address.payload.coordinate.lat,
                                                lng: address.payload.coordinate.lng
                                            }}
                                            radius={address.payload.radius * 1609.34}
                                            options={{
                                                fillColor: colors.primary.main,
                                                fillOpacity: 0.5,
                                                strokeColor: colors.primary.main,
                                                strokeOpacity: 1,
                                                strokeWeight: 3,
                                                zIndex: 1000
                                            }}
                                            onDragEnd={(e) => {
                                                let lat = e.latLng.lat()
                                                let lng = e.latLng.lng()

                                                getCoordinatesAddress(geocoder, lat, lng, function (addressData: any) {
                                                    setServiceArea(prevState => {
                                                        let copy = {...prevState}

                                                        copy.status = RecipientSearchStatus.New

                                                        for (let e of copy.addressRadiusSearches) {
                                                            if (e.id === address.id) {
                                                                e.payload.coordinate.lat = lat
                                                                e.payload.coordinate.lng = lng
                                                                e.radiusPinAddress = addressData.shortAddress
                                                                e.radiusPinStreet = addressData.streetName
                                                                break
                                                            }
                                                        }

                                                        return copy
                                                    })
                                                })

                                                setCanUserCheckout(false)
                                            }}
                                            draggable={true}
                                        />
                                    </MDBox>
                                })}

                                {serviceArea.polygonSearches.map((polygon, index) => {
                                    return <MDBox key={index}>
                                        <OverlayView
                                            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                            getPixelPositionOffset={(width, height) => ({
                                                x: -(width / 2),
                                                y: -(height / 2),
                                            })}
                                            position={getCenterOfPolygon(polygon.payload.coordinates)}
                                        >
                                            <Typography whiteSpace={"nowrap"} fontWeight={"bold"} textAlign={"center"}
                                                        color={colors.white.main}
                                                        sx={{textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black"}}>{polygon.name}</Typography>
                                        </OverlayView>

                                        <MapsPolygon
                                            key={polygon.id}
                                            draggable={true}
                                            editable={true}
                                            path={polygon.payload.coordinates}
                                            options={{
                                                fillColor: colors.primary.main,
                                                fillOpacity: 0.5,
                                                strokeColor: colors.primary.main,
                                                strokeOpacity: 1,
                                                strokeWeight: 3,
                                                zIndex: 1000
                                            }}
                                            onLoad={(p) => {
                                                onPolygonLoad(p, polygon.id)
                                            }}
                                            onUnmount={() => polygonsRef.current = []}
                                            onMouseUp={() => onPolygonEdit(polygon.id)}
                                            onDragEnd={() => onPolygonEdit(polygon.id)}
                                        />
                                    </MDBox>
                                })}

                                {serviceArea.hailStormCoordinates.map((coordinates, index) => {
                                    return <MDBox key={index}>
                                        <MapsPolygon
                                            path={coordinates.coordinates}
                                            options={{
                                                fillColor: colors.info.main,
                                                fillOpacity: 0.2,
                                                strokeColor: colors.info.main,
                                                strokeOpacity: 0.5,
                                                strokeWeight: 3
                                            }}
                                        />
                                    </MDBox>
                                })}

                                {serviceArea.hailTraceProfileServiceAreaUnionedCoordinates.map((coordinates, index) => {
                                    return <MDBox key={index}>
                                        <MapsPolygon
                                            path={coordinates.coordinates}
                                            options={{
                                                fillColor: colors.error.main,
                                                fillOpacity: 0.2,
                                                strokeColor: colors.error.main,
                                                strokeOpacity: 0.5,
                                                strokeWeight: 3
                                            }}
                                        />
                                    </MDBox>
                                })}
                            </GoogleMap>
                        }
                    </LoadScript>
                </MDBox>

                <MDBox mt={2}>
                    <MDCard>
                        <MDBox p={3}>
                            <Typography sx={{fontSize: 18}} fontWeight={"bold"} color={"secondary"} mb={2}>Order Details</Typography>

                            <TableContainer>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow sx={{'th': {border: "none"}, 'th:first-child': {paddingLeft: 0}}}>
                                            <TableCell>Search</TableCell>
                                            <TableCell align={"center"}>Search Type</TableCell>
                                            <TableCell align={"center"}>Quantity</TableCell>
                                            <TableCell align={"center"}>Each</TableCell>
                                            <TableCell align={"center"}>Amount</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {serviceArea.addressRadiusSearches.map((row, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{'td': {border: "none"}, 'td:first-child': {paddingLeft: 0}}}
                                            >
                                                <TableCell scope="row">{row.address}</TableCell>
                                                <TableCell align={"center"} scope="row">Radius</TableCell>
                                                <TableCell align={"center"}>{row.desiredQuantity ? formatNumber(row.desiredQuantity) : '-'}</TableCell>
                                                <TableCell align={"center"}>{!isPriceBeingRecalculated? (getAddressPrice(row) ? formatPrice(getAddressPrice(row)) : '-') : <CircularProgress size={"20px"} color="primary"/>}</TableCell>
                                                <TableCell align={"center"}>{!isPriceBeingRecalculated? (getAddressTotalPrice(row) ? formatPrice(getAddressTotalPrice(row)) : '-') : <CircularProgress size={"20px"} color="primary"/>}</TableCell>
                                            </TableRow>
                                        ))}

                                        {serviceArea.polygonSearches.map((row, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{'td': {border: "none"}, 'td:first-child': {paddingLeft: 0}}}
                                            >
                                                <TableCell>{row.name}</TableCell>
                                                <TableCell align={"center"}>Polygon</TableCell>
                                                <TableCell align={"center"}>{row.desiredQuantity ? formatNumber(row.desiredQuantity) : '-'}</TableCell>
                                                <TableCell align={"center"}>{!isPriceBeingRecalculated? (getPolygonPrice(row) ? formatPrice(getPolygonPrice(row)) : '-') : <CircularProgress size={"20px"} color="primary"/>}</TableCell>
                                                <TableCell align={"center"}>{!isPriceBeingRecalculated? (getPolygonTotalPrice(row) ? formatPrice(getPolygonTotalPrice(row)) : '-') : <CircularProgress size={"20px"} color="primary"/>}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <MDBox my={4}>
                                <Divider light={true}/>
                            </MDBox>

                            <MDBox mt={3}>
                                <MDButton
                                    fullWidth={true}
                                    color="primary"
                                    circular={true}
                                    onClick={() => {
                                        setShowApproveOrderModal(true)
                                    }}
                                    disabled={!canUserCheckout}
                                >
                                    <Grid container alignItems={"center"} justifyContent={"center"} gap={1}>
                                        <Grid item display={"flex"}>
                                            <CheckIcon/>
                                        </Grid>
                                        <Grid item>
                                            Approve Order
                                        </Grid>
                                    </Grid>
                                </MDButton>
                            </MDBox>
                        </MDBox>
                    </MDCard>
                </MDBox>
            </Grid>
        </Grid>

        <InfoModal
            show={showApproveOrderModal}
            setShow={setShowApproveOrderModal}
            headerText={'Are you sure you want to approve this service area?'}
            showCancelButton={true}
            showConfirmButton={true}
            cancelButtonOnClick={() => {
                setShowApproveOrderModal(false)
            }}
            confirmButtonOnClick={approveOrder}
        />

        <InfoModal
            show={showSuccessfulApprovalModal}
            setShow={setShowSuccessfulApprovalModal}
            headerText={'Service area successfully approved'}
            showCancelButton={false}
            showConfirmButton={true}
            confirmButtonOnClick={() => {
                setShowSuccessfulApprovalModal(false)
                setCanUserCheckout(false);
            }}
        />

        <InfoModal
            show={showErrorModal}
            setShow={setShowErrorModal}
            headerText={errorModalText}
            showCancelButton={false}
            showConfirmButton={true}
            confirmButtonOnClick={() => {
                setShowErrorModal(false)
            }}
        />

        <InfoModal
            show={showSuccessfulCountFetchModal}
            setShow={setShowSuccessfulCountFetchModal}
            headerText={successfulCountFetchText}
            showCancelButton={false}
            showConfirmButton={true}
            confirmButtonOnClick={() => {
                setShowSuccessfulCountFetchModal(false)
            }}
        />
    </MDBox>)
        :
        null
}

export default SuperAdminStormAutomationManagePage