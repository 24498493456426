import {useState, useEffect} from "react";
import {Dialog, DialogContent, LinearProgress} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "material-ui/components/MDBox";
import OrderRecipientService from "services/order-recipient";
import MDTypography from "material-ui/components/MDTypography";
import {useQueryClient} from "@tanstack/react-query";
import { useGlobal } from "context/global-context";

interface RecipientProcessModalProps {
    show: boolean
    orderId: number
    setShow: Function
}

function RecipientProcessModal({orderId, show, setShow}: RecipientProcessModalProps){
    const {setShowLoader} = useGlobal()

    const queryClient = useQueryClient()

    const [progress, setProgress] = useState(0)
    const [processedRecipients, setProcessedRecipients] = useState<number>(0)
    const [totalRecipients, setTotalRecipients] = useState<number>(0)

    const {getPostProcessStatus} = OrderRecipientService()
    const showProgressBar: boolean = false

    useEffect(function () {
        let i: NodeJS.Timer

        if(show){
                i = setInterval(() => {
                getPostProcessStatus(orderId)
                    .then((response) => {
                        if(response && !response.hasErrors && response.payload){
                            let processed = response.payload.processedRecipients
                            let total = response.payload.totalRecipients

                            let percentage = processed / total * 100

                            setProgress(percentage)

                            setProcessedRecipients(processed)
                            setTotalRecipients(total)

                            if(processed >= total){
                                clearInterval(i)

                                queryClient.invalidateQueries({queryKey: ["orderRecipients", orderId]})
                                setShow(false)
                                setShowLoader(false)
                            }
                        }
                    })
            }, 5000);
        }
        else{
            clearInterval(i)
            setProgress(0)
            setProcessedRecipients(0)
            setTotalRecipients(0)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show])

    return <Dialog open={show} maxWidth={false}>
        <DialogContent>
            <DialogTitle>Your recipients are being processed and it might take a while. Please wait</DialogTitle>

            {showProgressBar && (
                <MDBox width={"100%"} mt={3}>
                    <LinearProgress variant="determinate" value={progress} color={"primary"} />
                    <MDTypography textAlign={"right"} variant={"h5"}>{processedRecipients}/{totalRecipients}</MDTypography>
                </MDBox>
            )}
        </DialogContent>

    </Dialog>
}

export default RecipientProcessModal