import React, { useEffect, useRef, useState} from "react";
import MDBox from "material-ui/components/MDBox";
import {
    Alert,
    Box,
    Divider,
    FormControl,
    Grid,
    Icon,
    IconButton,
    InputLabel,
    Select,
    Slider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from "@mui/material";
import MDButton from "material-ui/components/MDButton";
import {Coordinate} from "models/coordinate";
import {Circle, GoogleMap, LoadScript, OverlayView, Polygon as MapsPolygon} from "@react-google-maps/api";
import colors from "material-ui/theme/base/colors";
import InfoModal from "components/info-modal";
import {Link, useNavigate, useParams} from "react-router-dom";
import CheckoutModal from "./components/checkout-modal";
import {AddressFilterData, PolygonFilterData, RecipientSearch} from "models/recipient-search/recipientSearch";
import {RecipientSearchStatus} from "models/recipient-search/recipientSearchStatus";
import {formatNumber, formatPrice} from "helpers/formatters";
import MDCard from "material-ui/components/MDCard";
import RadiusIcon from "assets/icons/radius";
import PolygonIcon from "assets/icons/polygon";
import CartIcon from "assets/icons/cart";
import MDInput from "material-ui/components/MDInput";
import DeleteIcon from "assets/icons/delete";
import MenuItem from "@mui/material/MenuItem";
import {getCenterOfPolygon, mapContainerStyle} from "helpers/google-map-helper";
import MDTypography from "material-ui/components/MDTypography";
import Loader from "components/loader";
import {OwnerOrRenterFilter, PropertyTypeFilter} from "models/radius-mailing/filters";
import Info2Icon from "assets/icons/info-2";
import RecipientSearchService from "services/recipient-search";
import { useGlobal } from "context/global-context";

function RadiusMailSearchPage() {
    const {setShowLoader} = useGlobal()
    const {getRecipientSearch, prepareRecipientSearch, postRecipientSearch} = RecipientSearchService()

    const minimumDesiredQuantity = 1;

    const [orderId, setOrderId] = useState<number>(parseInt(useParams().id))

    const [map, setMap] = useState<google.maps.Map>(null)
    const mapOptions: google.maps.MapOptions = {
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeId: 'hybrid',
        disableDefaultUI: true
    }
    const mapInfoTextContainer = {
        position: "absolute",
        top: 0,
        left: 0,
        transition: "transform 0.3s",
        transform: "translateY(0px)",
        backgroundColor: colors.primary.main,
        width: "100%",
        borderBottomLeftRadius: "4px",
        borderBottomRightRadius: "4px",
        textAlign: "center",
        padding: "5px 0",
        borderBottom: "2px solid white"
    }

    const [hasMapLoaded, setHasMapLoaded] = useState(false)

    const [center, setCenter] = useState<Coordinate>({lat: null, lng: null})
    const [zoom, setZoom] = useState<number>(7)

    const [recipientSearchData, setRecipientSearchData] = useState<RecipientSearch>({
        id: 0,
        totalPrice: null,
        multiUse: true,
        addressRadiusSearches: [],
        polygonSearches: [],
        recipientSearchFilter: {
            ownerOrRenterFilter: null,
            propertyTypeFilter: null,
            houseHoldIncomeFilter: null,
            lengthOfResidenceFilter: null,
            yearBuiltFilter: null
        },
        name: null,
        totalDesiredQuantity: minimumDesiredQuantity,
        totalAvailableQuantity: null,
        status: RecipientSearchStatus.New
    })

    const [showOrderSuccessModal, setShowOrderSuccessModal] = useState<boolean>(false)

    const [showSuccessfulCountFetchModal, setShowSuccessfulCountFetchModal] = useState<boolean>(false)
    const [successfulCountFetchText, setSuccessfulCountFetchText] = useState<string>(null)

    const [showSuccessfulCheckoutModal, setShowSuccessfulCheckoutModal] = useState<boolean>(false)

    const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
    const [errorModalText, setErrorModalText] = useState<string>(null)

    const [geocoder, setGeocoder] = useState(null)
    const [showCheckoutModal, setShowCheckoutModal] = useState<boolean>(false)
    const polygonsRef = useRef([])
    const navigate = useNavigate()

    const [canUserFetchCount, setCanUserFetchCount] = useState<boolean>(false)
    const [canUserCheckout, setCanUserCheckout] = useState<boolean>(false)
    const [canUserInteractWithUI, setCanUserInteractWithUI] = useState<boolean>(false)

    const [isAddingAddress, setIsAddingAddress] = useState<boolean>(false)
    const [isAddingPolygon, setIsAddingPolygon] = useState<boolean>(false)

    useEffect(function () {
        if (hasMapLoaded) {
            if (orderId) {
                getRecipientSearch(orderId).then((result) => {
                    if (!result.hasErrors) {
                        let payload = result.payload as RecipientSearch

                        if (payload.status === RecipientSearchStatus.ReadyForPayment) {
                            setCanUserCheckout(true)
                        }

                        if (payload.status === RecipientSearchStatus.ProcessingCount) {
                            startCountInterval(payload.id)
                        }

                        setRecipientSearchData(payload)
                    }
                })
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasMapLoaded])

    useEffect(function () {
        switch (recipientSearchData.status) {
            case RecipientSearchStatus.New:
                setCanUserCheckout(false)
                setCanUserInteractWithUI(true)

                if (!recipientSearchData.addressRadiusSearches.length && !recipientSearchData.polygonSearches.length) {
                    setCanUserFetchCount(false)
                }
                else {
                    let _a = true

                    for (let e of recipientSearchData.addressRadiusSearches) {
                        if (!e.payload.coordinate || !e.payload.radius || !e.payload.coordinate.lng || !e.payload.coordinate.lat) {
                            _a = false
                            break
                        }
                    }

                    setCanUserFetchCount(_a)
                }

                break
            case RecipientSearchStatus.ProcessingCount:
                setCanUserFetchCount(false)
                setCanUserCheckout(false)
                setCanUserInteractWithUI(false)

                break
            case RecipientSearchStatus.ReadyForPayment:
                setCanUserInteractWithUI(true)
                setCanUserFetchCount(false)

                let _a = true

                for(let e of recipientSearchData.addressRadiusSearches){
                    if (e.desiredQuantity < minimumDesiredQuantity || e.desiredQuantity > e.availableQuantity){
                        _a = false
                        break
                    }
                }

                for(let e of recipientSearchData.polygonSearches){
                    if (e.desiredQuantity < minimumDesiredQuantity || e.desiredQuantity > e.availableQuantity){
                        _a = false
                        break
                    }
                }

                setCanUserCheckout(_a)

                break
            case RecipientSearchStatus.Paid:
                setCanUserCheckout(false)
                setCanUserFetchCount(false)
                setCanUserInteractWithUI(false)

                break
            case RecipientSearchStatus.ProcessingOrder:
                setCanUserCheckout(false)
                setCanUserFetchCount(false)
                setCanUserInteractWithUI(false)

                break
            case RecipientSearchStatus.Complete:
                setCanUserCheckout(false)
                setCanUserFetchCount(false)
                setCanUserInteractWithUI(false)

                break
            case RecipientSearchStatus.Error:
                setCanUserCheckout(false)
                setCanUserFetchCount(true)
                setCanUserInteractWithUI(true)
                break
            case RecipientSearchStatus.UnexpectedError:
                setCanUserCheckout(false)
                setCanUserFetchCount(true)
                setCanUserInteractWithUI(true)
                setErrorModalText('An unexpected error occurred. Please try again or contact us at billing@lettrlabs.com')
                setShowErrorModal(true)

                break
            default:
                break
        }
    }, [recipientSearchData, minimumDesiredQuantity])

    //Helper function that transforms lat and lng to an address string
    function getCoordinatesAddress(lat: number, lng: number, cb: Function) {
        if (!lat || !lng) {
            cb({
                shortAddress: "",
                fullAddress: "",
                streetName: ""
            })
        }

        geocoder.geocode({location: new google.maps.LatLng(lat, lng)}, (geocoderResult: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
            if (status === google.maps.GeocoderStatus.OK) {
                if (geocoderResult[0] && geocoderResult[0].address_components[1]) {
                    let streetName = ''
                    let streetNumber = ''
                    let locality = ''

                    for (const component of geocoderResult[0].address_components) {
                        for (const type of component.types) {
                            if (type === "route") {
                                streetName = component.short_name
                            }
                            if (type === "street_number") {
                                streetNumber = component.long_name
                            }
                            if (type === "locality") {
                                locality = component.long_name
                            }
                        }
                    }

                    let fullAddress = geocoderResult[0].address_components[1].short_name
                    let shortAddress = fullAddress
                    if (!streetName) {
                        streetName = shortAddress
                    }
                    if (streetNumber && streetName) {
                        shortAddress = `${streetNumber} ${streetName}`
                        if (locality) {
                            fullAddress = `${streetNumber} ${streetName}, ${locality}`
                        }
                    }

                    cb({
                        shortAddress: shortAddress,
                        fullAddress: fullAddress,
                        streetName: streetName
                    })
                } else {
                    cb({
                        shortAddress: "",
                        fullAddress: "",
                        streetName: ""
                    })
                }
            } else {
                cb({
                    shortAddress: "",
                    fullAddress: "",
                    streetName: ""
                })
            }
        });
    }

    //Helper function that transforms an address string to a lat and lng object
    function getAddressCoordinates(address: string, cb: Function) {
        if (!address) {
            cb(null, null)
        }

        geocoder.geocode({address}, (results: any, status: any) => {
            if (status === 'OK' && results && results.length > 0) {
                const {lat, lng} = results[0].geometry.location

                cb(lat(), lng())
            }
            if (status === 'ZERO_RESULTS') {
                cb(null, null)
            }
        })
    }

    function onPolygonLoad(polygon: google.maps.Polygon, id: number) {
        polygonsRef.current.push({id: id, polygon: polygon})
    }

    function onPolygonEdit(id: number) {
        let polygonToEdit = (polygonsRef.current.find((e: any) => e.id === id)).polygon

        let updatedPath = polygonToEdit.getPath().getArray().map((coordinate: google.maps.LatLng) => {
            return {lat: coordinate.lat(), lng: coordinate.lng()}
        })

        let centerOfPolygon = getCenterOfPolygon(updatedPath)

        getCoordinatesAddress(centerOfPolygon.lat, centerOfPolygon.lng, (addressData: any) => {
            setRecipientSearchData(prevState => {
                let copy = {...prevState}

                copy.status = RecipientSearchStatus.New

                for (let el of copy.polygonSearches) {
                    if (el.id === id) {
                        el.payload.coordinates = updatedPath
                        el.name = addressData.shortAddress
                        break
                    }
                }

                return copy
            })
        })

        setCanUserCheckout(false)
    }

    function addPolygon(lat: number, lng: number, addressData: any) {
        setRecipientSearchData(prevState => {
            let copy = {...prevState}

            copy.status = RecipientSearchStatus.New

            let sortedPolygons = copy.polygonSearches.sort((a, b) => {
                if (a.id > b.id) {
                    return 1
                } else if (a.id < b.id) {
                    return -1
                }
                return 0
            })

            let lastId = sortedPolygons.length ? sortedPolygons[sortedPolygons.length - 1].id : 0

            let offset = 0.02898550724 //Approximate offset for the 4 points around the center (2 miles)

            let newPolygon: PolygonFilterData = {
                id: lastId + 1,
                name: addressData.shortAddress,
                availableQuantity: 0,
                desiredQuantity: 0,
                price: null,
                status: RecipientSearchStatus.New,
                payload: {
                    coordinates: [
                        {lat: lat, lng: lng - offset},
                        {lat: lat - offset, lng: lng},
                        {lat: lat, lng: lng + offset},
                        {lat: lat + offset, lng: lng},
                    ],
                },
                showMore: false,
                radiusPinAddress: "",
                radiusPinStreet: "",
                isNew: true
            }

            copy.polygonSearches.push(newPolygon)

            return copy
        })
    }

    function addAddress(lat: number, lng: number, addressData: any) {
        setRecipientSearchData(prevState => {
            let copy = {...prevState}

            copy.status = RecipientSearchStatus.New

            let sortedAddresses = copy.addressRadiusSearches.sort((a, b) => {
                if (a.id > b.id) {
                    return 1
                } else if (a.id < b.id) {
                    return -1
                }
                return 0
            })

            let lastId = sortedAddresses.length ? sortedAddresses[sortedAddresses.length - 1].id : 0

            let newAddress: AddressFilterData = {
                id: lastId + 1,
                address: addressData.shortAddress,
                availableQuantity: 0,
                desiredQuantity: 0,
                price: null,
                status: RecipientSearchStatus.New,
                isNew: true,
                radiusPinAddress: addressData.shortAddress,
                radiusPinStreet: addressData.streetName,
                custom1: null,
                custom2: null,
                custom3: null,
                custom4: null,
                custom5: null,
                custom6: null,
                showMore: false,
                payload: {
                    coordinate: {
                        lat: lat,
                        lng: lng
                    },
                    radius: 2,
                }
            }

            copy.addressRadiusSearches.push(newAddress)

            return copy
        })
    }

    function onMapLoaded() {
        setHasMapLoaded(true)

        setGeocoder(new window.google.maps.Geocoder())

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                function (pos: GeolocationPosition) {
                    let lat = pos.coords.latitude
                    let lng = pos.coords.longitude

                    setCenter({lat, lng})
                    setZoom(7)
                },
                function (error) {
                    setCenter({lat: 33.824386, lng: -118.1358666})
                    setZoom(10)
                })
        } else {
            setCenter({lat: 33.824386, lng: -118.1358666})
            setZoom(10)
        }
    }

    function deleteAddress(id: number) {
        setRecipientSearchData(prevState => {
            let copy = {...prevState}

            copy.status = RecipientSearchStatus.New

            copy.addressRadiusSearches = copy.addressRadiusSearches.filter((el) => el.id !== id)

            return copy
        })
    }

    function deletePolygon(id: number) {
        setRecipientSearchData(prevState => {
            return {...prevState, status: RecipientSearchStatus.New}
        })

        setRecipientSearchData(prevState => {
            let copy = {...prevState}

            copy.status = RecipientSearchStatus.New

            copy.polygonSearches = copy.polygonSearches.filter((el) => el.id !== id)

            return copy
        })
    }

    function getPolygonPrice(entry: PolygonFilterData) {
        if(entry.desiredQuantity) {
            let pricePerItem = 0.11
            let totalPrice = pricePerItem * entry.desiredQuantity;
            return parseFloat(totalPrice.toFixed(2))
        }
    }

    function getAddressPrice(entry: AddressFilterData) {
        if(entry.desiredQuantity){
            let pricePerItem = 0.11
            let totalPrice = pricePerItem * entry.desiredQuantity
            return parseFloat(totalPrice.toFixed(2))
        }
        return 0
    }

    function fetchCount() {
        setShowLoader(true)

        let postData = JSON.parse(JSON.stringify(recipientSearchData));

        for (let e of postData.addressRadiusSearches) {
            if (e.isNew) {
                e.id = 0
            }
        }

        for (let e of postData.polygonSearches) {
            if (e.isNew) {
                e.id = 0
            }
        }

        prepareRecipientSearch(postData).then((result1) => {
            if (result1.hasErrors) {
                setErrorModalText(result1.errors[0])
                setShowErrorModal(true)

                setShowLoader(false)

                return
            }

            let payload = result1.payload as RecipientSearch

            setRecipientSearchData({...payload, recipientSearchFilter: recipientSearchData.recipientSearchFilter})

            if (!orderId) {
                navigate("/recipient-search/order/" + payload.id)
                setOrderId(payload.id)
            }

            startCountInterval(payload.id)
        })
    }

    function startCountInterval(recipientSearchId: number) {
        setShowLoader(true)

        let interval = setInterval(function () {
            getRecipientSearch(recipientSearchId).then((result2) => {
                if (!result2) {
                    setErrorModalText('An unexpected error occurred. Please try again or contact us at billing@lettrlabs.com')
                    setShowErrorModal(true)

                    clearInterval(interval)
                    return
                }

                setRecipientSearchData({
                    ...result2.payload,
                    recipientSearchFilter: recipientSearchData.recipientSearchFilter
                })

                if (!result2.hasErrors) {
                    if (result2.payload.status === RecipientSearchStatus.ReadyForPayment) {
                        clearInterval(interval)
                        setShowLoader(false)

                        setSuccessfulCountFetchText(`Your order has returned a total of ${formatNumber(result2.payload.totalAvailableQuantity)} addresses`)
                        setShowSuccessfulCountFetchModal(true)
                    }
                    if (result2.payload.status === RecipientSearchStatus.Error) {
                        clearInterval(interval)
                        setShowLoader(false)
                    }
                    if (result2.payload.status === RecipientSearchStatus.UnexpectedError) {
                        clearInterval(interval)
                        setShowLoader(false)

                        setErrorModalText('An unexpected error occurred. Please try again or contact us at billing@lettrlabs.com')
                        setShowErrorModal(true)
                    }
                } else {
                    setErrorModalText(result2.errors[0])
                    setShowErrorModal(true)

                    clearInterval(interval)
                }
            })
        }, 5000)
    }

    async function updateRadiusMailingOrder() {
        let postData = {...recipientSearchData}

        for (let e of postData.addressRadiusSearches) {
            if (e.isNew) {
                e.id = 0
            }
        }

        for (let e of postData.polygonSearches) {
            if (e.isNew) {
                e.id = 0
            }
        }

        return postRecipientSearch(postData)
    }

    function handleCheckout() {
        setShowLoader(true)

        updateRadiusMailingOrder().then(() => {
            setShowLoader(false)

            setShowCheckoutModal(true)
        })
    }

    return <MDBox>
        <Grid container gap={3}>
            <Grid item flex={1} maxWidth={"700px"}>
                <MDCard>
                    <MDBox p={3}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography mb={2} fontSize={24} fontWeight={"bold"} color={"secondary"}>
                                {orderId ? "Edit" : "New"} Recipient Search
                            </Typography>
                            <Tooltip
                                componentsProps={{ tooltip: { sx: { width: "300px", maxWidth: "unset" } } }} 
                                title={"Purchase recipients in an area and add your filters. Once purchased the data will end up in your address book and in the radius mail page, from there you can create mailings."}
                            >
                                <Box>
                                    <Info2Icon color={"light2"}/>
                                </Box>
                            </Tooltip>
                        </Box>
                        <MDCard border={true} borderRadiusSize={"xl"} borderColor={"light"} boxShadow={false}>
                            <MDBox p={2}>
                                <Typography sx={{fontSize: 18}} fontWeight={"bold"} color={"secondary"}>Search</Typography>

                                <Grid container mt={3} gap={2}>
                                    <Grid item>
                                        <MDButton
                                            color="primary"
                                            onClick={() => setIsAddingAddress(true)}
                                            disabled={!canUserInteractWithUI}
                                        >
                                            <Grid container alignItems={"center"} justifyContent={"center"} gap={1}>
                                                <Grid item display={"flex"}>
                                                    <RadiusIcon/>
                                                </Grid>
                                                <Grid item>
                                                    Radius
                                                </Grid>
                                            </Grid>
                                        </MDButton>
                                    </Grid>

                                    <Grid item>
                                        <MDButton
                                            color="primary"
                                            onClick={() => setIsAddingPolygon(true)}
                                            disabled={!canUserInteractWithUI}
                                        >
                                            <Grid container alignItems={"center"} justifyContent={"center"} gap={1}>
                                                <Grid item display={"flex"}>
                                                    <PolygonIcon/>
                                                </Grid>
                                                <Grid item>
                                                    Polygon
                                                </Grid>
                                            </Grid>
                                        </MDButton>
                                    </Grid>
                                </Grid>

                                <MDBox mt={3}>
                                    {recipientSearchData.addressRadiusSearches.map((entry, index) => {
                                        return <MDCard sx={{marginBottom: "25px"}} key={index} border={true} borderColor={"light"} boxShadow={false} borderRadiusSize={"lg"}>
                                            <MDBox p={1} pt={2}>
                                                <Grid container pl={1} mb={4} alignItems={"center"}
                                                      justifyContent={"space-between"}>
                                                    <Grid item>
                                                        <Typography variant={"h4"}>
                                                            {entry.address}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item display={"flex"}>
                                                        <Grid container alignItems={"center"} justifyContent={"center"}
                                                              flexWrap={"nowrap"} gap={"10px"}>
                                                            <Grid item display={"flex"}>
                                                                <Tooltip title={"Show more"}>
                                                                    <IconButton color={"primary"} sx={{padding: 0}}
                                                                                onClick={() => {
                                                                                    setRecipientSearchData(prevState => {
                                                                                        let copy = {...prevState}

                                                                                        for (let e of copy.addressRadiusSearches) {
                                                                                            if (e.id === entry.id) {
                                                                                                e.showMore = !e.showMore

                                                                                                break
                                                                                            }
                                                                                        }

                                                                                        return copy
                                                                                    })
                                                                                }}>
                                                                        {entry.showMore ?
                                                                            <Icon fontSize={"medium"}>keyboard_arrow_up</Icon>
                                                                            :
                                                                            <Icon fontSize={"medium"}>keyboard_arrow_down</Icon>
                                                                        }
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>

                                                            <Grid item display={"flex"} flexWrap={"nowrap"}>
                                                                <Tooltip title={"Go to Address"}>
                                                                    <IconButton color={"primary"} sx={{padding: 0}}
                                                                                disabled={!entry.payload.coordinate.lat || !entry.payload.coordinate.lng}
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation()
                                                                                    setCenter({
                                                                                        lat: entry.payload.coordinate.lat,
                                                                                        lng: entry.payload.coordinate.lng
                                                                                    })
                                                                                    setZoom(12)
                                                                                }}>
                                                                        <Icon sx={{cursor: "pointer"}}
                                                                              fontSize={"medium"}>my_location</Icon>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>

                                                            <Grid item display={"flex"} flexWrap={"nowrap"}>
                                                                <Tooltip title={"Delete Address"} onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    deleteAddress(entry.id)
                                                                }}>
                                                                    <IconButton color={"error"} sx={{padding: 0}}>
                                                                        <DeleteIcon/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <MDBox mt={2} pb={1}>
                                                    <Grid container gap={"20px"}>
                                                        <Grid item flex={1}>
                                                            <MDInput
                                                                fullWidth={true}
                                                                type="text"
                                                                label="Address"
                                                                value={entry.address}
                                                                InputLabelProps={{shrink: true}}
                                                                onChange={(event: any) => {
                                                                    setRecipientSearchData(prevState => {
                                                                        let copy = {...prevState}

                                                                        copy.status = RecipientSearchStatus.New

                                                                        for (let e of copy.addressRadiusSearches) {
                                                                            if (e.id === entry.id) {
                                                                                e.address = event.target.value

                                                                                break
                                                                            }
                                                                        }

                                                                        return copy
                                                                    })

                                                                    getAddressCoordinates(event.target.value, function (lat: number, lng: number) {
                                                                        if (lat && lng) {
                                                                            setCenter({lat, lng})
                                                                        }

                                                                        getCoordinatesAddress(lat, lng, (addressData: any) => {
                                                                            setRecipientSearchData(prevState => {
                                                                                let copy = {...prevState}

                                                                                copy.status = RecipientSearchStatus.New

                                                                                for (let e of copy.addressRadiusSearches) {
                                                                                    if (e.id === entry.id) {
                                                                                        e.payload.coordinate = {
                                                                                            lat, lng
                                                                                        }
                                                                                        e.radiusPinAddress = addressData.shortAddress
                                                                                        e.radiusPinStreet = addressData.streetName

                                                                                        break
                                                                                    }
                                                                                }

                                                                                return copy
                                                                            })
                                                                        })
                                                                    })
                                                                }}
                                                            />
                                                        </Grid>

                                                        <Grid item>
                                                            <MDInput
                                                                fullWidth={true}
                                                                type="number"
                                                                inputProps={{
                                                                    step: 0.1
                                                                }}
                                                                label="Radius (miles)"
                                                                value={entry.payload.radius}
                                                                InputLabelProps={{shrink: true}}
                                                                onChange={(event: any) => {
                                                                    setRecipientSearchData(prevState => {
                                                                        let copy = {...prevState}

                                                                        copy.status = RecipientSearchStatus.New

                                                                        let miles = parseFloat(event.target.value)

                                                                        if (miles > 30) {
                                                                            miles = 30
                                                                        }
                                                                        if (miles < 0) {
                                                                            miles = 0
                                                                        }

                                                                        for (let e of copy.addressRadiusSearches) {
                                                                            if (e.id === entry.id) {
                                                                                e.payload.radius = miles
                                                                                break
                                                                            }
                                                                        }

                                                                        return copy
                                                                    })
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>

                                                    {entry.showMore ?
                                                        <MDBox>
                                                            <Divider light={true}/>

                                                            <Grid container mt={2} alignItems={"center"} gap={1}>
                                                                <Grid item flex={1}>
                                                                    <MDInput
                                                                        fullWidth={true}
                                                                        type="text"
                                                                        label="Radius Pin Address"
                                                                        value={entry.radiusPinAddress}
                                                                        InputLabelProps={{shrink: true}}
                                                                        onChange={(event: any) => {
                                                                            setRecipientSearchData(prevState => {
                                                                                let copy = {...prevState}

                                                                                copy.status = RecipientSearchStatus.New

                                                                                for (let e of copy.addressRadiusSearches) {
                                                                                    if (e.id === entry.id) {
                                                                                        e.radiusPinAddress = event.target.value
                                                                                        break
                                                                                    }
                                                                                }

                                                                                return copy
                                                                            })
                                                                        }}
                                                                    />
                                                                </Grid>

                                                                <Grid item>
                                                                    <Tooltip title={`This is a mail merge field that uses the radius pin's address ex. "123 Main St."`}>
                                                                        <MDBox display={"flex"}>
                                                                            <Info2Icon color={"dark"}/>
                                                                        </MDBox>
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid container mt={2} alignItems={"center"} gap={1}>
                                                                <Grid item flex={1}>
                                                                    <MDInput
                                                                        fullWidth={true}
                                                                        type="text"
                                                                        label="Radius Pin Street"
                                                                        value={entry.radiusPinStreet}
                                                                        InputLabelProps={{shrink: true}}
                                                                        onChange={(event: any) => {
                                                                            setRecipientSearchData(prevState => {
                                                                                let copy = {...prevState}

                                                                                copy.status = RecipientSearchStatus.New

                                                                                for (let e of copy.addressRadiusSearches) {
                                                                                    if (e.id === entry.id) {
                                                                                        e.radiusPinStreet = event.target.value

                                                                                        break
                                                                                    }
                                                                                }

                                                                                return copy
                                                                            })
                                                                        }}
                                                                    />
                                                                </Grid>

                                                                <Grid item>
                                                                    <Tooltip title={`This is a mail merge field that uses the radius pin's street ex. "Main St."`}>
                                                                        <MDBox display={"flex"}>
                                                                            <Info2Icon color={"dark"}/>
                                                                        </MDBox>
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>
                                                        </MDBox>
                                                        :
                                                        null
                                                    }

                                                    {entry.availableQuantity < minimumDesiredQuantity &&
                                                        <MDBox mt={3} pl={2} pr={2}>
                                                            <Typography>
                                                                Not return enough results - please widen the search
                                                            </Typography>
                                                        </MDBox>
                                                    }
                                                    {entry.status === RecipientSearchStatus.ReadyForPayment && (
                                                        entry.availableQuantity >= minimumDesiredQuantity ? (
                                                            <MDBox mt={3} pl={2}>
                                                                <Grid container alignItems={"center"} justifyContent={"space-between"} gap={"20px"}>
                                                                    <Grid item flex={1}>
                                                                        <Grid container alignItems={"center"} justifyContent={"center"} gap={2}>
                                                                            <Grid item>
                                                                                <Typography>{minimumDesiredQuantity}</Typography>
                                                                            </Grid>
                                                                            <Grid item display={"flex"} flex={1}>
                                                                                <Slider
                                                                                    min={minimumDesiredQuantity}
                                                                                    max={entry.availableQuantity}
                                                                                    valueLabelDisplay="auto"
                                                                                    disabled={!canUserInteractWithUI}
                                                                                    value={entry.desiredQuantity}
                                                                                    valueLabelFormat={(value) => {
                                                                                        return formatNumber(value)
                                                                                    }}
                                                                                    onChange={(event, value: any) => {
                                                                                        setRecipientSearchData(prevState => {
                                                                                            let copy = {...prevState}

                                                                                            for (let e of copy.addressRadiusSearches) {
                                                                                                if (e.id === entry.id) {
                                                                                                    e.desiredQuantity = value
                                                                                                    break
                                                                                                }
                                                                                            }

                                                                                            return copy
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Typography>{formatNumber(entry.availableQuantity)}</Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid item maxWidth={"180px"}>
                                                                        <MDInput
                                                                            fullWidth={true}
                                                                            label="Desired Quantity"
                                                                            value={formatNumber(entry.desiredQuantity)}
                                                                            error={entry.desiredQuantity < minimumDesiredQuantity || entry.desiredQuantity > entry.availableQuantity}
                                                                            InputLabelProps={{shrink: true}}
                                                                            onChange={(event: any) => {
                                                                                let val = event.target.value

                                                                                val = val.replaceAll(",", "").replaceAll(".", "")

                                                                                val = parseInt(val)

                                                                                if(!val || isNaN(val)) {
                                                                                    val = 0
                                                                                }

                                                                                setRecipientSearchData(prevState => {
                                                                                    let copy = {...prevState}

                                                                                    for (let e of copy.addressRadiusSearches) {
                                                                                        if (e.id === entry.id) {
                                                                                            e.desiredQuantity = val
                                                                                            break
                                                                                        }
                                                                                    }

                                                                                    return copy
                                                                                })
                                                                            }}
                                                                        />

                                                                        {entry.desiredQuantity < minimumDesiredQuantity || entry.desiredQuantity > entry.availableQuantity?
                                                                            <MDTypography
                                                                                variant={"h6"}
                                                                                fontWeight={"regular"}
                                                                                color="error"
                                                                            >
                                                                                Quantity must be between {minimumDesiredQuantity} and {formatNumber(entry.availableQuantity)}
                                                                            </MDTypography>
                                                                            :
                                                                            null
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </MDBox>
                                                        ) : (
                                                            <MDBox mt={3} pl={2} pr={2}>
                                                                <Typography>Not return enough results - please widen the search</Typography>
                                                            </MDBox>
                                                        )
                                                    )}
                                                </MDBox>
                                            </MDBox>
                                        </MDCard>
                                    })}

                                    {recipientSearchData.polygonSearches.length ?
                                        recipientSearchData.polygonSearches.sort((a, b) => {
                                            if (a.id > b.id) {
                                                return 1
                                            } else if (a.id < b.id) {
                                                return -1
                                            }
                                            return 0
                                        }).map((entry, index) => {
                                            return <MDCard sx={{marginBottom: "25px"}} key={index} border={true} borderColor={"light"} boxShadow={false} borderRadiusSize={"lg"}>
                                                <MDBox p={1} pt={2} pb={2}>
                                                    <Grid container pl={1} alignItems={"center"}
                                                          justifyContent={"space-between"}>
                                                        <Grid item>
                                                            <Typography variant={"h4"}>
                                                                {entry.name}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item display={"flex"}>
                                                            <Grid container alignItems={"center"}
                                                                  justifyContent={"center"} gap={"10px"}>

                                                                <Grid item display={"flex"} flexWrap={"nowrap"}>
                                                                    <Tooltip title={"Go to Polygon"}>
                                                                        <IconButton color={"primary"} sx={{padding: 0}}
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation()

                                                                                        setCenter(getCenterOfPolygon(entry.payload.coordinates))

                                                                                        setZoom(12)
                                                                                    }}>
                                                                            <Icon sx={{cursor: "pointer"}}
                                                                                  fontSize={"medium"}>my_location</Icon>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Grid>

                                                                <Grid item display={"flex"} flexWrap={"nowrap"}>
                                                                    <Tooltip title={"Delete Polygon"} onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        deletePolygon(entry.id)
                                                                    }}>
                                                                        <IconButton color={"error"} sx={{padding: 0}}>
                                                                            <DeleteIcon/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    {entry.availableQuantity < minimumDesiredQuantity &&
                                                        <MDBox mt={3} pl={2} pr={2}>
                                                            <Typography>Not return enough results - please widen the
                                                                search</Typography>
                                                        </MDBox>
                                                    }
                                                    {entry.status === RecipientSearchStatus.ReadyForPayment && (
                                                        entry.availableQuantity >= minimumDesiredQuantity ? (
                                                            <MDBox mt={3} pl={2}>
                                                                <Grid container alignItems={"center"}
                                                                    justifyContent={"center"} gap={"20px"}>
                                                                    <Grid item flex={1}>
                                                                        <Grid container alignItems={"center"}
                                                                            justifyContent={"center"} gap={"20px"}>
                                                                            <Grid item>
                                                                                <Typography>{minimumDesiredQuantity}</Typography>
                                                                            </Grid>
                                                                            <Grid item display={"flex"} flex={1}>
                                                                                <Slider
                                                                                    min={minimumDesiredQuantity}
                                                                                    max={entry.availableQuantity}
                                                                                    valueLabelDisplay="auto"
                                                                                    disabled={!canUserInteractWithUI}
                                                                                    value={entry.desiredQuantity}
                                                                                    valueLabelFormat={(value) => {
                                                                                        return formatNumber(value)
                                                                                    }}
                                                                                    onChange={(event, value: any) => {
                                                                                        setRecipientSearchData(prevState => {
                                                                                            let copy = {...prevState}

                                                                                            for (let e of copy.polygonSearches) {
                                                                                                if (e.id === entry.id) {
                                                                                                    e.desiredQuantity = value
                                                                                                    break
                                                                                                }
                                                                                            }

                                                                                            return copy
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Typography>{formatNumber(entry.availableQuantity)}</Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid item maxWidth={"180px"}>
                                                                        <MDInput
                                                                            fullWidth={true}
                                                                            label="Desired Quantity"
                                                                            error={entry.desiredQuantity < minimumDesiredQuantity || entry.desiredQuantity > entry.availableQuantity}
                                                                            value={formatNumber(entry.desiredQuantity)}
                                                                            InputLabelProps={{shrink: true}}
                                                                            onChange={(event: any) => {
                                                                                let val = event.target.value

                                                                                val = val.replaceAll(",", "").replaceAll(".", "")

                                                                                val = parseInt(val)

                                                                                if(!val || isNaN(val)) {
                                                                                    val = 0
                                                                                }

                                                                                setRecipientSearchData(prevState => {
                                                                                    let copy = {...prevState}

                                                                                    for (let e of copy.polygonSearches) {
                                                                                        if (e.id === entry.id) {
                                                                                            e.desiredQuantity = val
                                                                                            break
                                                                                        }
                                                                                    }

                                                                                    return copy
                                                                                })
                                                                            }}
                                                                        />

                                                                        {entry.desiredQuantity < minimumDesiredQuantity || entry.desiredQuantity > entry.availableQuantity?
                                                                            <MDTypography
                                                                                variant={"h6"}
                                                                                fontWeight={"regular"}
                                                                                color="error"
                                                                            >
                                                                                Quantity must be between {minimumDesiredQuantity} and {formatNumber(entry.availableQuantity)}
                                                                            </MDTypography>
                                                                            :
                                                                            null
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </MDBox>
                                                        ) : (
                                                            <MDBox mt={3} pl={2} pr={2}>
                                                                <Typography>Not return enough results - please widen the search</Typography>
                                                            </MDBox>
                                                        )
                                                    )}
                                                </MDBox>
                                            </MDCard>
                                        })
                                        :
                                        null
                                    }
                                </MDBox>

                                <MDBox my={3}>
                                    <Divider light={true}/>
                                </MDBox>

                                <MDBox>
                                    <Typography sx={{fontSize: 18}} fontWeight={"bold"} color={"secondary"}>Filters</Typography>

                                    <MDBox mt={3}>
                                        <FormControl fullWidth={true}>
                                            <InputLabel htmlFor="owner-type-select" shrink={true}>Owner Type</InputLabel>
                                            <Select
                                                fullWidth={true}
                                                id={"owner-type-select"}
                                                label={"Owner Type"}
                                                disabled={!canUserInteractWithUI}
                                                value={recipientSearchData.recipientSearchFilter.ownerOrRenterFilter ?? ""}
                                                displayEmpty={true}
                                                onChange={(event) => {
                                                    let value = event.target.value
                                                    setRecipientSearchData((prevState: RecipientSearch) => {
                                                        return {
                                                            ...prevState,
                                                            status: RecipientSearchStatus.New,
                                                            recipientSearchFilter: {
                                                                ...prevState.recipientSearchFilter,
                                                                ownerOrRenterFilter: value as OwnerOrRenterFilter
                                                            }
                                                        }
                                                    })
                                                }}
                                            >
                                                <MenuItem value={""}>All</MenuItem>
                                                <MenuItem value={OwnerOrRenterFilter.Owner}>Owner</MenuItem>
                                                <MenuItem value={OwnerOrRenterFilter.Renter}>Renter</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </MDBox>

                                    <MDBox mt={2}>
                                        <FormControl fullWidth={true}>
                                            <InputLabel htmlFor="property-type-select" shrink={true}>Property Type</InputLabel>
                                            <Select
                                                fullWidth={true}
                                                id={"property-type-select"}
                                                disabled={!canUserInteractWithUI}
                                                label={"Property Type"}
                                                value={recipientSearchData.recipientSearchFilter.propertyTypeFilter ?? ""}
                                                displayEmpty={true}
                                                onChange={(event) => {
                                                    let value = event.target.value

                                                    setRecipientSearchData(prevState => {
                                                        return {
                                                            ...prevState,
                                                            status: RecipientSearchStatus.New,
                                                            recipientSearchFilter: {
                                                                ...prevState.recipientSearchFilter,
                                                                propertyTypeFilter: value as PropertyTypeFilter
                                                            }
                                                        }
                                                    })
                                                }}
                                            >
                                                <MenuItem value={""}>All</MenuItem>
                                                <MenuItem value={PropertyTypeFilter["Single Family Dwelling Unit"]}>Single Family Dwelling Unit</MenuItem>
                                                <MenuItem value={PropertyTypeFilter["Multi Family Dwelling Unit"]}>Multi Family Dwelling Unit</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </MDBox>

                                    <MDBox mt={4} pr={2}>
                                        <Typography sx={{fontSize: 18}} fontWeight={"bold"} color={"secondary"}>Household Income</Typography>

                                        <MDBox pl={1} mt={1}>
                                            <Slider
                                                min={0}
                                                max={250000}
                                                value={[
                                                    recipientSearchData.recipientSearchFilter.houseHoldIncomeFilter ? recipientSearchData.recipientSearchFilter.houseHoldIncomeFilter.min ?? 0 : 0,
                                                    recipientSearchData.recipientSearchFilter.houseHoldIncomeFilter ? recipientSearchData.recipientSearchFilter.houseHoldIncomeFilter.max ?? 250000 : 250000
                                                ]}
                                                valueLabelDisplay="auto"
                                                valueLabelFormat={(str) => {
                                                    return `$${formatNumber(str)}`
                                                }}
                                                disabled={!canUserInteractWithUI}
                                                step={10000}
                                                disableSwap={true}
                                                onChange={(e, value, activeThumb) => {
                                                    value = value as number[]

                                                    let min = value[0];
                                                    let max = value[1];

                                                    const minDistance = 10000

                                                    let oldMin = recipientSearchData.recipientSearchFilter.houseHoldIncomeFilter?.min?? 0
                                                    let oldMax = recipientSearchData.recipientSearchFilter.houseHoldIncomeFilter?.max?? 250000

                                                    if (activeThumb === 0) {
                                                        min = Math.min(min, oldMax - minDistance)
                                                        max = oldMax
                                                    }
                                                    else {
                                                        min = oldMin
                                                        max = Math.max(max, oldMin + minDistance)
                                                    }

                                                    setRecipientSearchData(prevState => {
                                                        return {
                                                            ...prevState,
                                                            status: RecipientSearchStatus.New,
                                                            recipientSearchFilter: {
                                                                ...prevState.recipientSearchFilter,
                                                                houseHoldIncomeFilter: (min !== null || max !== null) ? {
                                                                    min: min,
                                                                    max: max
                                                                } : null
                                                            }
                                                        }
                                                    })
                                                }}
                                                marks={[
                                                    {value: 0, label: "$0"},
                                                    {value: 50000, label: "$50,000"},
                                                    {value: 100000, label: "$100,000"},
                                                    {value: 150000, label: "$150,000"},
                                                    {value: 200000, label: "$200,000"},
                                                    {value: 250000, label: "$250,000+"},
                                                ]}
                                            />
                                        </MDBox>
                                    </MDBox>

                                    <MDBox mt={2} pr={2}>
                                        <Typography sx={{fontSize: 18}} fontWeight={"bold"} color={"secondary"}>Length of Residency</Typography>

                                        <MDBox pl={1} mt={1}>
                                            <Slider
                                                min={0}
                                                max={15}
                                                value={[
                                                    recipientSearchData.recipientSearchFilter.lengthOfResidenceFilter ? recipientSearchData.recipientSearchFilter.lengthOfResidenceFilter.min ?? 0 : 0,
                                                    recipientSearchData.recipientSearchFilter.lengthOfResidenceFilter ? recipientSearchData.recipientSearchFilter.lengthOfResidenceFilter.max ?? 15 : 15
                                                ]}
                                                valueLabelDisplay="auto"
                                                disabled={!canUserInteractWithUI}
                                                step={1}
                                                disableSwap={true}
                                                onChange={(e, value, activeThumb) => {
                                                    value = value as number[]

                                                    let min = value[0];
                                                    let max = value[1];

                                                    const minDistance = 1

                                                    let oldMin = recipientSearchData.recipientSearchFilter.lengthOfResidenceFilter?.min?? 0
                                                    let oldMax = recipientSearchData.recipientSearchFilter.lengthOfResidenceFilter?.max?? 15

                                                    if (activeThumb === 0) {
                                                        min = Math.min(min, oldMax - minDistance)
                                                        max = oldMax
                                                    }
                                                    else {
                                                        min = oldMin
                                                        max = Math.max(max, oldMin + minDistance)
                                                    }

                                                    min = min === 0 ? null : min
                                                    max = max === 15 ? null : max

                                                    setRecipientSearchData(prevState => {
                                                        return {
                                                            ...prevState,
                                                            status: RecipientSearchStatus.New,
                                                            recipientSearchFilter: {
                                                                ...prevState.recipientSearchFilter,
                                                                lengthOfResidenceFilter: (min !== null || max !== null) ? {
                                                                    min: min,
                                                                    max: max
                                                                } : null
                                                            }
                                                        }
                                                    })
                                                }}
                                                marks={[
                                                    {value: 0, label: "0"},
                                                    {value: 1, label: "1"},
                                                    {value: 2, label: "2"},
                                                    {value: 3, label: "3"},
                                                    {value: 4, label: "4"},
                                                    {value: 5, label: "5"},
                                                    {value: 6, label: "6"},
                                                    {value: 7, label: "7"},
                                                    {value: 8, label: "8"},
                                                    {value: 9, label: "9"},
                                                    {value: 10, label: "10"},
                                                    {value: 11, label: "11"},
                                                    {value: 12, label: "12"},
                                                    {value: 13, label: "13"},
                                                    {value: 14, label: "14"},
                                                    {value: 15, label: "15+"}
                                                ]}
                                            />
                                        </MDBox>
                                    </MDBox>

                                    <MDBox mt={2} pr={2}>
                                        <Typography sx={{fontSize: 18}} fontWeight={"bold"} color={"secondary"}>Year Built</Typography>

                                        <MDBox pl={1} mt={1}>
                                            <Slider
                                                min={1890}
                                                max={2010}
                                                value={[
                                                    recipientSearchData.recipientSearchFilter.yearBuiltFilter ? recipientSearchData.recipientSearchFilter.yearBuiltFilter.min ?? 1890 : 1890,
                                                    recipientSearchData.recipientSearchFilter.yearBuiltFilter ? recipientSearchData.recipientSearchFilter.yearBuiltFilter.max ?? 2010 : 2010
                                                ]}
                                                valueLabelDisplay="auto"
                                                disabled={!canUserInteractWithUI}
                                                step={10}
                                                onChange={(e, value, activeThumb) => {
                                                    value = value as number[]

                                                    let min = value[0];
                                                    let max = value[1];

                                                    const minDistance = 10

                                                    let oldMin = recipientSearchData.recipientSearchFilter.yearBuiltFilter?.min?? 1890
                                                    let oldMax = recipientSearchData.recipientSearchFilter.yearBuiltFilter?.max?? 2010

                                                    if (activeThumb === 0) {
                                                        min = Math.min(min, oldMax - minDistance)
                                                        max = oldMax
                                                    }
                                                    else {
                                                        min = oldMin
                                                        max = Math.max(max, oldMin + minDistance)
                                                    }

                                                    setRecipientSearchData(prevState => {
                                                        return {
                                                            ...prevState,
                                                            status: RecipientSearchStatus.New,
                                                            recipientSearchFilter: {
                                                                ...prevState.recipientSearchFilter,
                                                                yearBuiltFilter: (min !== null || max !== null) ? {
                                                                    min: min,
                                                                    max: max
                                                                } : null
                                                            }
                                                        }
                                                    })
                                                }}
                                                marks={[
                                                    {value: 1890, label: "< 1900"},
                                                    {value: 1900, label: "1900"},
                                                    {value: 1910, label: "1910"},
                                                    {value: 1920, label: "1920"},
                                                    {value: 1930, label: "1930"},
                                                    {value: 1940, label: "1940"},
                                                    {value: 1950, label: "1950"},
                                                    {value: 1960, label: "1960"},
                                                    {value: 1970, label: "1970"},
                                                    {value: 1980, label: "1980"},
                                                    {value: 1990, label: "1990"},
                                                    {value: 2000, label: "2000"},
                                                    {value: 2010, label: "> 2000"},
                                                ]}
                                            />
                                        </MDBox>
                                    </MDBox>
                                </MDBox>
                            </MDBox>
                        </MDCard>

                        <MDBox mt={3}>
                            <MDButton
                                fullWidth={true}
                                color="primary"
                                circular={true}
                                disabled={!canUserFetchCount}
                                onClick={fetchCount}
                            >
                                <Grid container alignItems={"center"} justifyContent={"center"} gap={1}>
                                    <Grid item display={"flex"}>
                                        <RadiusIcon/>
                                    </Grid>
                                    <Grid item>
                                        Calculate Targets
                                    </Grid>
                                </Grid>
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </MDCard>
            </Grid>

            <Grid item flex={1}>
                <MDBox display={"flex"} alignItems={"center"} justifyContent={"center"} sx={{height: "700px"}}>
                    <LoadScript googleMapsApiKey="AIzaSyAnZBcPxYjFTJ2p5VykxLnOcqM0gY4uIY0" onLoad={onMapLoaded} loadingElement={<Loader/>}>
                        {hasMapLoaded &&
                            <GoogleMap
                                onLoad={(map) => {
                                    setMap(map)
                                }}
                                mapContainerStyle={mapContainerStyle}
                                center={center}
                                zoom={zoom}
                                onZoomChanged={() => {
                                    if (map) {
                                        setZoom(map.getZoom())
                                    }
                                }}
                                options={mapOptions}
                                onClick={(e: google.maps.MapMouseEvent) => {
                                    let lat = e.latLng.lat()
                                    let lng = e.latLng.lng()

                                    if (isAddingAddress) {
                                        getCoordinatesAddress(lat, lng, (addressData: any) => {
                                            addAddress(e.latLng.lat(), e.latLng.lng(), addressData)
                                        })

                                        setIsAddingAddress(false)
                                    }
                                    if (isAddingPolygon) {
                                        getCoordinatesAddress(lat, lng, (addressData: any) => {
                                            addPolygon(lat, lng, addressData)
                                        })

                                        setIsAddingPolygon(false)
                                    }
                                }}>
                                <MDBox sx={{
                                    ...mapInfoTextContainer,
                                    transform: isAddingAddress ? "translateY(0px)" : "translateY(-50px)"
                                }}>
                                    <Typography variant={"h4"} color={colors.white.main}>
                                        Click anywhere on the map to add an address
                                    </Typography>
                                </MDBox>

                                <MDBox sx={{
                                    ...mapInfoTextContainer,
                                    transform: isAddingPolygon ? "translateY(0px)" : "translateY(-50px)"
                                }}>
                                    <Typography variant={"h4"} color={colors.white.main}>
                                        Click anywhere on the map to add a polygon
                                    </Typography>
                                </MDBox>

                                {recipientSearchData.addressRadiusSearches.filter((a) => a.payload.coordinate.lat && a.payload.coordinate.lng).map((address, index) => {
                                    return <MDBox key={index}>
                                        <OverlayView
                                            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                            getPixelPositionOffset={(width, height) => ({
                                                x: -(width / 2),
                                                y: -(height / 2),
                                            })}
                                            position={{
                                                lat: address.payload.coordinate.lat,
                                                lng: address.payload.coordinate.lng
                                            }}
                                        >
                                            <Typography whiteSpace={"nowrap"} fontWeight={"bold"} textAlign={"center"}
                                                        color={colors.white.main}
                                                        sx={{textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black"}}>{address.address}</Typography>
                                        </OverlayView>

                                        <Circle
                                            draggable={canUserInteractWithUI}
                                            center={{
                                                lat: address.payload.coordinate.lat,
                                                lng: address.payload.coordinate.lng
                                            }}
                                            radius={address.payload.radius * 1609.34}
                                            options={{
                                                fillColor: colors.primary.main,
                                                fillOpacity: 0.30,
                                                strokeColor: colors.primary.main,
                                                strokeOpacity: 0.8
                                            }}
                                            onDragEnd={(e) => {
                                                let lat = e.latLng.lat()
                                                let lng = e.latLng.lng()

                                                getCoordinatesAddress(lat, lng, function (addressData: any) {
                                                    setRecipientSearchData(prevState => {
                                                        let copy = {...prevState}

                                                        copy.status = RecipientSearchStatus.New

                                                        for (let e of copy.addressRadiusSearches) {
                                                            if (e.id === address.id) {
                                                                e.payload.coordinate.lat = lat
                                                                e.payload.coordinate.lng = lng
                                                                e.address = addressData.shortAddress
                                                                e.radiusPinAddress = addressData.shortAddress
                                                                e.radiusPinStreet = addressData.streetName
                                                                break
                                                            }
                                                        }

                                                        return copy
                                                    })
                                                })

                                                setCanUserCheckout(false)
                                            }}
                                        />
                                    </MDBox>
                                })}

                                {recipientSearchData.polygonSearches.map((polygon, index) => {
                                    return <MDBox key={index}>
                                        <OverlayView
                                            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                            getPixelPositionOffset={(width, height) => ({
                                                x: -(width / 2),
                                                y: -(height / 2),
                                            })}
                                            position={getCenterOfPolygon(polygon.payload.coordinates)}
                                        >
                                            <Typography whiteSpace={"nowrap"} fontWeight={"bold"} textAlign={"center"}
                                                        color={colors.white.main}
                                                        sx={{textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black"}}>{polygon.name}</Typography>
                                        </OverlayView>

                                        <MapsPolygon
                                            key={polygon.id}
                                            draggable={canUserInteractWithUI}
                                            editable={canUserInteractWithUI}
                                            path={polygon.payload.coordinates}
                                            options={{
                                                fillColor: colors.primary.main,
                                                fillOpacity: 0.40,
                                                strokeColor: colors.primary.main,
                                                strokeOpacity: 1,
                                                strokeWeight: 3
                                            }}
                                            onLoad={(p) => {
                                                onPolygonLoad(p, polygon.id)
                                            }}
                                            onUnmount={() => polygonsRef.current = []}
                                            onMouseUp={() => onPolygonEdit(polygon.id)}
                                            onDragEnd={() => onPolygonEdit(polygon.id)}
                                        />
                                    </MDBox>
                                })}
                            </GoogleMap>
                        }
                    </LoadScript>
                </MDBox>


                <MDBox mt={2}>
                    <MDCard>
                        <MDBox p={3}>
                            <Typography sx={{fontSize: 18}} fontWeight={"bold"} color={"secondary"} mb={2}>Order Details</Typography>

                            <TableContainer>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow sx={{'th': {border: "none"}, 'th:first-child': {paddingLeft: 0}}}>
                                            <TableCell>Search</TableCell>
                                            <TableCell align={"center"}>Search Type</TableCell>
                                            <TableCell align={"center"}>Quantity</TableCell>
                                            <TableCell align={"center"}>Each</TableCell>
                                            <TableCell align={"center"}>Amount</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {recipientSearchData.addressRadiusSearches.map((row, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{'td': {border: "none"}, 'td:first-child': {paddingLeft: 0}}}
                                            >
                                                <TableCell scope="row">{row.address}</TableCell>
                                                <TableCell align={"center"} scope="row">Radius</TableCell>
                                                <TableCell align={"center"}>{row.desiredQuantity ? formatNumber(row.desiredQuantity) : '-'}</TableCell>
                                                <TableCell align={"center"}>$0.11</TableCell>
                                                <TableCell align={"center"}>{getAddressPrice(row) ? formatPrice(getAddressPrice(row)) : '-'}</TableCell>
                                            </TableRow>
                                        ))}

                                        {recipientSearchData.polygonSearches.map((row, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{'td': {border: "none"}, 'td:first-child': {paddingLeft: 0}}}
                                            >
                                                <TableCell>{row.name}</TableCell>
                                                <TableCell align={"center"}>Polygon</TableCell>
                                                <TableCell align={"center"}>{row.desiredQuantity ? formatNumber(row.desiredQuantity) : '-'}</TableCell>
                                                <TableCell align={"center"}>$0.11</TableCell>
                                                <TableCell align={"center"}>
                                                    {getPolygonPrice(row) ? formatPrice(getPolygonPrice(row)) : '-'}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <MDBox my={4}>
                                <Divider light={true}/>
                            </MDBox>

                            {!canUserCheckout && canUserFetchCount && (
                                <Alert variant="outlined" severity="info" color="warning" sx={{ padding: "0 12px", marginBottom: 2 }}>
                                    You must calculate targets before checking out
                                </Alert>
                            )}

                            <MDButton
                                fullWidth={true}
                                color="primary"
                                circular={true}
                                disabled={!canUserCheckout}
                                onClick={handleCheckout}
                            >
                                <Grid container alignItems={"center"} justifyContent={"center"} gap={1}>
                                    <Grid item display={"flex"}>
                                        <CartIcon/>
                                    </Grid>
                                    <Grid item>
                                        Checkout
                                    </Grid>
                                </Grid>
                            </MDButton>
                        </MDBox>
                    </MDCard>
                </MDBox>
            </Grid>
        </Grid>

        <CheckoutModal
            show={showCheckoutModal}
            setShow={setShowCheckoutModal}
            recipientSearchData={recipientSearchData}
            setRecipientSearchData={setRecipientSearchData}
            showSuccessfulCheckoutModal={showSuccessfulCheckoutModal}
            setShowSuccessfulCheckoutModal={setShowSuccessfulCheckoutModal}
        />

        <InfoModal
            show={showErrorModal}
            setShow={setShowErrorModal}
            headerText={errorModalText}
            showCancelButton={false}
            showConfirmButton={true}
            confirmButtonOnClick={() => {
                setShowErrorModal(false)
            }}
        />

        <InfoModal
            show={showSuccessfulCountFetchModal}
            setShow={setShowSuccessfulCountFetchModal}
            headerText={successfulCountFetchText}
            showCancelButton={false}
            showConfirmButton={true}
            confirmButtonOnClick={() => {
                setShowSuccessfulCountFetchModal(false)
            }}
        />

        <InfoModal
            show={showSuccessfulCheckoutModal}
            setShow={setShowSuccessfulCheckoutModal}
            headerText={<>Your order has been placed! Please give us up to 30 minutes to build your list before
                ordering. You may need to refresh the history page to see updated results.</>}
            showCancelButton={false}
            showConfirmButton={true}
            confirmButtonOnClick={() => {
                setShowSuccessfulCheckoutModal(false);
                navigate('/recipient-search')
            }}
        />

        <InfoModal
            show={showOrderSuccessModal}
            setShow={setShowOrderSuccessModal}
            headerText={<>Your address count has been successfully submitted. Please click <Link
                to={"/recipient-search"}>here</Link> to complete your order</>}
            showCancelButton={false}
            showConfirmButton={false}
        />
    </MDBox>
}

export default RadiusMailSearchPage