import MDBox from "material-ui/components/MDBox";
import MDCard from "material-ui/components/MDCard";
import OrderStepper from "../components/stepper";
import React, {useEffect, useState} from "react";
import ProductSubpage from "../components/subpages/1-product-subpage";
import DesignSubpage from "../components/subpages/2-design-subpage";
import ReturnAddressSubpage from "../components/subpages/3-return-address-subpage";
import RecipientsSubpage from "../components/subpages/4-recipients-subpage";
import ReviewSubpage from "../components/subpages/5-review-subpage";
import CheckoutSubpage from "../components/subpages/6-checkout-subpage";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {useParams} from "react-router-dom";
import OrderService from "services/order";
import {OrderSteps} from "models/orderSteps";
import {EditorProvider, useEditor} from "context/editor-context";
import {PageSettingsProvider} from "context/page-settings-context";
import SuccessSubpage from "../components/subpages/7-success-subpage";
import {Grid} from "@mui/material";
import {Order} from "models/order";
import {OrderArtifactType} from "models/enums/orderArtifactType";
import {OrderStatus} from "models/enums/orderStatus";
import OrderRecipientService from "services/order-recipient";
import {IOrderRecipient, OrderRecipientError, OrderRecipientWarning} from "models/orderRecipient";
import {IProduct} from "models/product";
import {OrderParameter} from "models/orderParameter";
import {ProductTypes} from "models/enums/ProductTypes";
import { useGlobal } from "context/global-context";
import { OrderFontDefaults } from "models/enums/OrderDefaults";
import { OrderRecipientsSearchParams } from "models/gridSearchParams";
import { defaultSearchParams } from "components/table/table.utils";

interface ContentProps {
    order: Order
    setOrder: Function
}

function Content({order, setOrder}: ContentProps) {
    const {setNavigationCallback, setShowLoader} = useGlobal()
    const queryClient = useQueryClient()
    const params = useParams()
    const id = parseInt(params.id)

    const {postOrder, postOrderParameters} = OrderService()
    const {getOrderRecipientsPaginated} = OrderRecipientService()

    const {saveCurrentScene, resetEditor, setKeepOutWhite} = useEditor()

    const [activeStep, setActiveStep] = useState<OrderSteps>(null)
    const [maxStep, setMaxStep] = useState<OrderSteps>(null)

    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)

    const [isInitialSpinner, setIsInitialSpinner] = useState<boolean>(true)
    const [searchParams, setSearchParams] = useState<OrderRecipientsSearchParams>({...defaultSearchParams, showError: false, showWarning: false});

    const steps = [
        {
            key: 0,
            label: "Product"
        },
        {
            key: 1,
            label: "Design"
        },
        {
            key: 2,
            label: "Return Address"
        },
        {
            key: 3,
            label: "Recipients"
        },
        {
            key: 4,
            label: "Review"
        },
        {
            key: 5,
            label: "Checkout"
        },
    ]

    //--- Product Page Variables
    const [showWarningModal, setShowWarningModal] = useState<boolean>(false)
    const [product, setProduct] = useState<IProduct>(null)
    const [orderName, setOrderName] = useState<string>("")

    //--- Design Page Variables
    const [hasSceneWarnings, setHasSceneWarnings] = useState<boolean>(false)
    const [orderParameters, setOrderParameters] = useState<OrderParameter[]>([])
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false)

    //--- Recipients Page Variables
    const [orderRecipients, setOrderRecipients] = useState<IOrderRecipient[]>([])
    const [hasUnproccessedRecipients, setHasUnproccessedRecipients] = useState<boolean>(false)
    const [undeliverableRows, setUndeliverableRows] = useState<IOrderRecipient[]>([])
    const [potentiallyUndeliverableRows, setPotentiallyUndeliverableRows] = useState<IOrderRecipient[]>([])
    const [missingMailMergeRows, setMissingMailMergeRows] = useState<IOrderRecipient[]>([])
    const [missingAddressFieldRows, setMissingAddressFieldRows] = useState<IOrderRecipient[]>([])
    const [showRecipientsErrorModal, setShowRecipientsErrorModal] = useState<boolean>(false)

    //--- Review Page Variables
    const [checked, setChecked] = useState<boolean>(false)

    const getRecipientsQuery = useQuery({
        queryKey: ["orderRecipients", id, searchParams],
        queryFn: () => {
            const { page, pageSize, sortBy, sortDirection, search, showWarning, showError } = searchParams;
            return getOrderRecipientsPaginated(
                order.id, 
                page, 
                pageSize, 
                sortBy, 
                sortDirection, 
                search, 
                showWarning, 
                showError
            );
        },
        refetchOnWindowFocus: false
    })

    const postOrderMutation = useMutation({
        mutationFn: () => {
            return postOrder(order)
        },
        onMutate: () => {
            setShowLoader(true)
        },
        onSuccess: () => {
            setShowLoader(false)
        }
    })

    // Every time the user has changed something on the designer step or the return address step, create a callback function that will be called before navigating away using the menu on the left
    useEffect(() => {
        if(!order || !order.id || (activeStep !== OrderSteps.DesignSubpage && activeStep !== OrderSteps.ReturnAddressSubpage)){
            setNavigationCallback(null)
            return
        }

        setNavigationCallback(() => {
            return async () => {
                setShowLoader(true)

                await saveCurrentScene(activeStep === OrderSteps.DesignSubpage? "Card" : "Envelope")

                resetEditor()

                await postOrderParameters(order.id, orderParameters)

                await postOrderMutation.mutateAsync().then(() => {
                    queryClient.invalidateQueries({queryKey: ["order", order.id]})
                    queryClient.invalidateQueries({queryKey: ["orderParameters", order.id]})
                })
            }
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order, activeStep])

    // Decides which step the user should start on and which should be the max step
    useEffect(() => {
        if (!order || !orderRecipients) {
            return
        }

        if(isFirstLoad){
            if(order.orderArtifacts.find(a => a.artifactType === OrderArtifactType.CreativeEditorScene)){
                setActiveStep(OrderSteps.DesignSubpage)
            }
            else{
                setActiveStep(OrderSteps.ProductSubpage)
            }

            setIsFirstLoad(false)
        }

        if (!order.product) {
            setMaxStep(OrderSteps.ProductSubpage)

            return
        }

        if (!order.orderArtifacts.find(a => a.artifactType === OrderArtifactType.CreativeEditorScene)) {
            setMaxStep(OrderSteps.DesignSubpage)

            return
        }

        if (!order.orderArtifacts.find(a => a.artifactType === OrderArtifactType.EnvelopeScene)) {
            setMaxStep(OrderSteps.ReturnAddressSubpage)

            return
        }

        if (!orderRecipients?.length) {
            setMaxStep(OrderSteps.RecipientsSubpage)

            return
        }

        if (order.orderStatus !== OrderStatus.Paid) {
            setMaxStep(OrderSteps.CheckoutSubpage)

            return
        }

        if (order.orderStatus === OrderStatus.Paid) {
            setMaxStep(OrderSteps.SuccessSubpage)

            return
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order, orderRecipients]);

    useEffect(() => {
        if (getRecipientsQuery.data) {
            setOrderRecipients(getRecipientsQuery.data.payload.recipients)
            setHasUnproccessedRecipients(getRecipientsQuery.data.payload.hasUnprocessedRecipients)
        }
    }, [getRecipientsQuery.data]);

    useEffect(() => {
        if(isInitialSpinner){
            if (order && activeStep !== null) {
                setShowLoader(false)

                setIsInitialSpinner(false)
            }
            else{
                setShowLoader(true)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order, activeStep]);

    useEffect(() => {
        let _missingAddressFieldRows = []
        let _undeliverableRows = []
        let _potentiallyUndeliverableRows = []
        let _missingMailMergeRows = []

        setMissingAddressFieldRows([])
        setUndeliverableRows([])
        setPotentiallyUndeliverableRows([])
        setMissingMailMergeRows([])

        for (let recipient of orderRecipients) {
            if(recipient.errors?.length){
                if(recipient.errors.includes(OrderRecipientError.MissingAddressFields)){
                    _missingAddressFieldRows.push(recipient)
                }
                if(recipient.errors.includes(OrderRecipientError.MissingNameFields)){
                    _missingAddressFieldRows.push(recipient)
                }
                if(recipient.errors.includes(OrderRecipientError.NonUsAddress)){
                    _missingAddressFieldRows.push(recipient)
                }
            }

            if(recipient.warnings?.length){
                if(recipient.warnings.includes(OrderRecipientWarning.MissingMailMergeField)){
                    _missingMailMergeRows.push(recipient)
                }
                if(recipient.warnings.includes(OrderRecipientWarning.PotentiallyUndeliverable)){
                    _potentiallyUndeliverableRows.push(recipient)
                }
                if(recipient.warnings.includes(OrderRecipientWarning.Undeliverable)){
                    _undeliverableRows.push(recipient)
                }
            }
        }

        setMissingAddressFieldRows(_missingAddressFieldRows)
        setUndeliverableRows(_undeliverableRows)
        setPotentiallyUndeliverableRows(_potentiallyUndeliverableRows)
        setMissingMailMergeRows(_missingMailMergeRows)
    }, [orderRecipients]);

    async function onNext(newStep?: number) {
        if (activeStep === OrderSteps.ProductSubpage) {
            let sceneArtifact = order.orderArtifacts.find(
                (a) => a.artifactType === OrderArtifactType.CreativeEditorScene
            );

            // Show warning if there's a scene
            if (sceneArtifact && order.product !== product.name) {
                setShowWarningModal(true)
            } else {
                order.product = product.name
                if ((order?.fontSizeReturnAddress ?? 0) === 0)
                    order.fontSizeReturnAddress = product.name === ProductTypes.HandwrittenPostCardA8 ? OrderFontDefaults.DefaultPostcardFontSizeReturnAddress : OrderFontDefaults.DefaultHandwrittenFontSizeReturnAddress;
                order.name = orderName

                await postOrderMutation.mutateAsync()
                setShowWarningModal(false)

                setActiveStep(newStep != null ? newStep : OrderSteps.DesignSubpage)
            }
        } else if (activeStep === OrderSteps.DesignSubpage) {
            if (hasSceneWarnings) {
                setShowErrorModal(true)

                return
            }

            await setKeepOutWhite()

            setShowLoader(true)

            await saveCurrentScene("Card")

            resetEditor()

            await postOrderParameters(order.id, orderParameters)

            postOrderMutation.mutateAsync().then(() => {
                queryClient.invalidateQueries({queryKey: ["order", order.id]})
                queryClient.invalidateQueries({queryKey: ["orderParameters", order.id]})

                setActiveStep(newStep != null ? newStep : OrderSteps.ReturnAddressSubpage)
            })
        } else if (activeStep === OrderSteps.ReturnAddressSubpage) {
            setShowLoader(true)

            await saveCurrentScene("Envelope")

            postOrderMutation.mutateAsync().then(() => {
                resetEditor()

                queryClient.invalidateQueries({queryKey: ["order", order.id]})

                setActiveStep(newStep != null ? newStep : OrderSteps.RecipientsSubpage)
            })
        } else if (activeStep === OrderSteps.RecipientsSubpage) {
            if(missingAddressFieldRows.length){
                setShowRecipientsErrorModal(true)
                return
            }
            setActiveStep(newStep != null ? newStep : OrderSteps.ReviewSubpage)
        }
        else if (activeStep === OrderSteps.ReviewSubpage) {
            if (!checked) {
                return
            }
            setActiveStep(newStep != null ? newStep : OrderSteps.CheckoutSubpage)
        }
    }

    async function onBack(newStep?: number) {
        if (activeStep === OrderSteps.DesignSubpage) {
            setShowLoader(true)

            await saveCurrentScene("Card")

            resetEditor()

            await postOrderParameters(order.id, orderParameters)

            postOrderMutation.mutateAsync().then(() => {
                queryClient.invalidateQueries({queryKey: ["order", order.id]})
                queryClient.invalidateQueries({queryKey: ["orderParameters", order.id]})

                setActiveStep(newStep != null ? newStep : OrderSteps.ProductSubpage)
            })
        } else if (activeStep === OrderSteps.ReturnAddressSubpage) {
            setShowLoader(true)

            await saveCurrentScene("Envelope")

            postOrderMutation.mutateAsync().then(() => {
                resetEditor()

                queryClient.invalidateQueries({queryKey: ["order", order.id]})

                setActiveStep(newStep != null ? newStep : OrderSteps.DesignSubpage)
            })
        } else if (activeStep === OrderSteps.RecipientsSubpage) {
            setActiveStep(newStep != null ? newStep : OrderSteps.ReturnAddressSubpage)
        } else if (activeStep === OrderSteps.ReviewSubpage) {
            setActiveStep(newStep != null ? newStep : OrderSteps.ReturnAddressSubpage)
        } else if (activeStep === OrderSteps.CheckoutSubpage) {
            setActiveStep(newStep != null ? newStep : OrderSteps.ReviewSubpage)
        }
    }

    return <Grid container flexDirection={"column"} height={"100%"} gap={2} flexWrap={"nowrap"}>
        <Grid item>
            <MDCard color={"white"} borderRadiusSize={"xxxl"} boxShadow={false}>
                <MDBox py={2}>
                    <OrderStepper 
                        steps={steps} 
                        activeStep={activeStep} 
                        maxStep={maxStep} 
                        onBack={(newStep?: number) => activeStep !== OrderSteps.ReturnAddressSubpage ? onBack(newStep) : null}
                        onNext={(newStep?: number) => activeStep !== OrderSteps.ReturnAddressSubpage ? onNext(newStep) : null}
                    />
                </MDBox>
            </MDCard>
        </Grid>

        <Grid item flex={1}>
            {activeStep === OrderSteps.ProductSubpage &&
                <ProductSubpage
                    order={order}
                    setOrder={setOrder}
                    orderName={orderName}
                    setOrderName={setOrderName}
                    showWarningModal={showWarningModal}
                    setShowWarningModal={setShowWarningModal}
                    product={product}
                    setProduct={setProduct}
                    onNext={onNext}
                />
            }

            {activeStep === OrderSteps.DesignSubpage &&
                <PageSettingsProvider order={order}>
                    <DesignSubpage
                        order={order}
                        setOrder={setOrder}
                        orderParameters={orderParameters}
                        setOrderParameters={setOrderParameters}
                        onBack={onBack}
                        onNext={onNext}
                        hasSceneWarnings={hasSceneWarnings}
                        setHasSceneWarnings={setHasSceneWarnings}
                        showErrorModal={showErrorModal}
                        setShowErrorModal={setShowErrorModal}
                    />
                </PageSettingsProvider>
            }

            {activeStep === OrderSteps.ReturnAddressSubpage &&
                <PageSettingsProvider order={order}>
                    <ReturnAddressSubpage
                        order={order}
                        setOrder={setOrder}
                        onBack={onBack}
                        onNext={onNext}
                    />
                </PageSettingsProvider>
            }

            {activeStep === OrderSteps.RecipientsSubpage &&
                <RecipientsSubpage
                    order={order}
                    setOrder={setOrder}
                    onNext={onNext}
                    onBack={onBack}
                    recipients={orderRecipients}
                    totalRowCount={getRecipientsQuery.data?.payload?.totalCount}
                    isRecipientsLoading={getRecipientsQuery.isFetching}
                    hasUnproccessedRecipients={hasUnproccessedRecipients}
                    showErrorModal={showRecipientsErrorModal}
                    setShowErrorModal={setShowRecipientsErrorModal}
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    usedColumns={getRecipientsQuery.data?.payload?.usedColumns}
                />
            }

            {activeStep === OrderSteps.ReviewSubpage &&
                <ReviewSubpage
                    order={order}
                    setActiveStep={setActiveStep}
                    checked={checked}
                    setChecked={setChecked}
                    recipients={orderRecipients}
                    undeliverableRows={undeliverableRows}
                    potentiallyUndeliverableRows={potentiallyUndeliverableRows}
                    missingAddressFieldRows={missingAddressFieldRows}
                    missingMailMergeRows={missingMailMergeRows}
                    totalRowCount={getRecipientsQuery.data?.payload?.totalCount}
                />
            }

            {activeStep === OrderSteps.CheckoutSubpage &&
                <CheckoutSubpage order={order} setOrder={setOrder} setActiveStep={setActiveStep} onBack={onBack}/>
            }

            {activeStep === OrderSteps.SuccessSubpage &&
                <SuccessSubpage order={order}/>
            }
        </Grid>
    </Grid>
}

function OrderPage() {
    const params = useParams()
    const id = parseInt(params.id)

    const {getOrder} = OrderService()

    const [order, setOrder] = useState<Order>(null)

    const getOrderQuery = useQuery({
        queryKey: ["order", id],
        queryFn: () => {
            return getOrder(id)
        },
        refetchOnWindowFocus: false
    })

    useEffect(() => {
        if (getOrderQuery.data) {
            setOrder(getOrderQuery.data[0])
        }
    }, [getOrderQuery.data]);

    return <EditorProvider order={order} setOrder={setOrder}>
        <Content order={order} setOrder={setOrder}/>
    </EditorProvider>
}

export default OrderPage